<template>
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>

    <v-card elevation="2" class="pa-5 mt-5">
      <v-card-title>
        カテゴリ新規作成
      </v-card-title>

      <v-card-text>
        <v-alert v-if="alert" :type="status" :value="alert" dismissible text>
          {{ statusMessage }}
        </v-alert>

        <v-row>
          <v-col>
            <v-radio-group v-model="category.type" row>
              <v-radio label="詳細画面なし" value="common"></v-radio>
              <v-radio label="詳細画面あり" value="course"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <!-- <v-row v-if="category.type == 'common'">
          <v-col md="6">
            <v-select
              ref="parent_id"
              v-model="category.parent_id"
              :items="main_categories"
              item-value="id"
              item-text="name"
              label="Category"
            />
          </v-col>
        </v-row> -->
        <div v-if="category.type == 'common' && brand_id == 2">
          <v-row v-for="(items, index) in categories" :key="index">
            <v-col md="6">
              <v-select
                ref="parent_id"
                v-model="items.parent_id"
                v-on:change="checkCategory(items.parent_id, index)"
                :items="items"
                item-value="id"
                item-text="name"
                label="カテゴリタイプ"
                return-object
              />
            </v-col>
          </v-row>
        </div>

        <v-row>
          <v-col cols="12" md="6"
            ><v-text-field
              v-model="category.name_jp"
              ref="name_jp"
              :rules="rules.name_jp"
              :error-messages="errorMessages.name_jp"
              label="カテゴリ名"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6"
            ><v-text-field
              v-if="brand_id == 2"
              v-model="category.name_en"
              ref="name_en"
              :rules="rules.name_en"
              :error-messages="errorMessages.name_en"
              label="英語表記"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6"
            ><v-text-field
              v-model="category.slug"
              ref="slug"
              :error-messages="errorMessages.slug"
              :rules="rules.slug"
              label="スラグ"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6"
            ><v-text-field
              v-model="category.display_order"
              ref="display_order"
              :error-messages="errorMessages.display_order"
              label="表示順"
            ></v-text-field>
          </v-col>
        </v-row>
        
        <v-row>
            <v-col v-if="category.type == 'common'" cols="12" md="12">
            <v-textarea
              ref="description"
              v-model="category.description"
              rows="1"
              auto-grow
              label="カテゴリーコメント"
              id="description"
            ></v-textarea>
          </v-col>
            <v-col v-if="category.type == 'course'" cols="12" md="12">
            <v-textarea
              ref="appealing_text"
              v-model="category.appealing_text"
              rows="1"
              auto-grow
              label="カテゴリーコメント"
              id="appealing_text"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-file-input
          v-if="brand_id == 2"
          @change="previewImage"
          v-model="category.image"
          ref="image"
          :rules="rules.image"
          :error-messages="errorMessages.image"
          show-size
          label="画像"
        >
        </v-file-input>
        <v-img :src="url" max-height="150" max-width="250"></v-img>

        <v-switch
          v-model="category.status"
          ref="status"
          label="表示/非表示"
        ></v-switch>

        <v-divider class="mt-12"></v-divider>
        <v-card-actions>
          <v-btn text :to="{ name: 'Category' }">
            戻る
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="submit"
            :loading="loading"
            :disabled="loading"
          >
            更新
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import CategoryAPI from '@/services/category'
export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: 'ホーム',
          disabled: false,
          to: { name: 'Dashboard' }
        },
        {
          text: 'カテゴリ',
          disabled: false,
          to: { name: 'Category' },
          exact: true
        },
        {
          text: '新規作成',
          disabled: false,
          to: { name: 'CreateCategory' },
          exact: true
        }
      ],
      alert: false,
      status: null,
      statusMessage: null,
      url: null,
      categories: [],
      main_categories: [],
      category: {
        type: 'common',
        parent_id: null,
        name_jp: null,
        name_en: null,
        slug: null,
        display_order: 0,
        image: null,
        status: true
      },
      rules: {
        name_jp: [v => !!v || 'カテゴリ名 は必須項目です'],
        // name_en: [v => !!v || '英語表記 は必須項目です'],
        slug: [v => !!v || 'スラグ は必須項目です'],
        // image: [
        //   v => !v || v.size < 2000000 || 'Image size should be less than 2 MB!'
        // ]
      },
      loading: false,
      errorMessages: '',
      brand_id: null
    }
  },

  beforeMount() {
    this.brand_id = this.$store.getters.brands.find(
      x => x.name === this.$route.params.brand
    ).id
    this.getMainCategories()
  },

  methods: {
    previewImage() {
      if (this.category.image) {
        this.url = URL.createObjectURL(this.category.image)
      }
    },
    resetForm() {
      this.errorMessages = []
      Object.keys(this.form).forEach(f => {
        this.$refs[f].reset()
      })
      this.url = null
    },
    submit() {
      this.formHasErrors = false
      Object.keys(this.rules).forEach(f => {
        let success = this.$refs[f].validate(true)
        if (!success) {
          this.formHasErrors = true
        }
      })
      if (!this.formHasErrors) {
        this.createCategory()
      }
    },
    getMainCategories() {
      CategoryAPI.getCategories({
        shop_id: this.brand_id,
        parent_id: 'null'
      })
        .then(result => {
          let resultArray = [
            { id: null, name: 'Main Category', parent_id: null }
          ]
          result.data.data.map(function(item, key) {
            if (item.name != '') {
              resultArray.push({
                id: item.id,
                name: item.name_jp,
                parent_id: item.parent_id
              })
            }
          })
          this.main_categories = resultArray
          this.main_categories.parent_id = null
          this.categories.push(this.main_categories)
        })
        .catch(err => {
          console.log(err)
        })
    },
    checkCategory(category, index) {
      this.categories.splice(index + 1)
      if (!category.id) {
        this.category.parent_id = null
        return
      }
      this.category.parent_id = category.id
      if (category.name == 'Select') {
        return
      }
      CategoryAPI.getChildren(category.id)
        .then(result => {
          let resultArray = [
            { id: category.id, name: 'Select', parent_id: category.id }
          ]
          result.data.data.data.map(function(item, key) {
            if (item.name != '') {
              resultArray.push({
                id: item.id,
                name: item.name_jp,
                parent_id: item.parent_id
              })
            }
          })
          if (resultArray.length > 1) {
            this.categories.push(resultArray)
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    createCategory() {
      this.loading = true
      CategoryAPI.createCategory(this.formData)
        .then(res => {
          this.status = res.data.status
          this.statusMessage = res.data.message

          this.loading = false
          let payload = {
            status: res.data.status,
            message: res.data.message
          }

          this.$store.commit('setAlert', payload)

          this.$router.push({
            name: 'Category',
            params: { id: this.$route.params.brand }
          })
        })
        .catch(e => {
          this.loading = false
          this.errorMessages = e.data.errors || 'unexpected error'
        })
    }
  },

  computed: {
    form() {
      return {
        type: this.category.type,
        parent_id: this.category.parent_id,
        name_en: this.category.name_en,
        name_jp: this.category.name_jp,
        slug: this.category.slug,
        display_order: this.category.display_order,
        image: this.category.image,
        status: this.category.status ? 1 : 0,
        description: this.category.type == 'common' ? this.category.description : '',
        appealing_text: this.category.type == 'course' ? this.category.appealing_text : ''
      }
    },
    formData() {
      var formData = new FormData()
      Object.keys(this.form).forEach(key => {
        if (!(this.form[key] == null || this.form[key] === '')) {
          formData.append(key, this.form[key])
        }
      })
      formData.append('shop_id', this.brand_id)
      return formData
    }
  }
}
</script>
