<template>
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>

    <v-alert v-if="alert" :type="alert.status" dismissible text>
      {{ alert.message }}
    </v-alert>

    <v-card elevation="2" class="pa-5 mt-5">
      <v-card-title>
        メニュー一覧
      </v-card-title>

      <v-row class="px-5">
        <v-col md="3">
          <treeselect
            v-model="category"
            :multiple="false"
            :options="categories"
            placeholder="全て"
            class="category-tree"
          />
        </v-col>
        <v-col md="3">
          <v-select
            ref="status"
            v-model="status"
            :items="statuses"
            item-value="value"
            item-text="text"
            label="ステータス"
            outlined
            dense
          />
        </v-col>
        <v-col class="text-right">
          <v-btn
            small
            color="secondary white--text"
            :to="{ name: 'CreateMenu' }"
          >
            メニュー作成
          </v-btn>
        </v-col>
      </v-row>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="menus"
          :options.sync="options"
          :server-items-length="totalMenus"
          :loading="loading"
        >
          <template v-slot:item="{ item, index }">
            <tr>
              <td>
                {{ options.itemsPerPage * (options.page - 1) + (index + 1) }}
              </td>
              <td>
                <div class="d-flex align-center">
                  <v-img
                    v-if="item.image"
                    class="pr-4"
                    max-height="60"
                    max-width="60"
                    :src="item.image"
                  ></v-img>
                </div>
              </td>
              <td>
                <span class="pl-1">{{ item.name_jp }}</span>
              </td>
              <td class="submenu" v-html="item.price"></td>
              <td>{{ item.category_name }}</td>
              <td>{{ item.display_order }}</td>
              <td>{{ item.status }}</td>
              <td>
                {{ moment(item.updated_at).format('YYYY/MM/DD') }}
              </td>

              <td>
                <v-btn
                  small
                  text
                  color="secondary"
                  class="px-0 mx-0"
                  :to="{
                    name: 'MenuDetail',
                    params: { id: item.id, brand: brand_name }
                  }"
                  >詳細
                  <!-- <v-icon>mdi-eye</v-icon> -->
                </v-btn>
                <v-btn
                  small
                  text
                  class="px-0 mx-0"
                  :to="{
                    name: 'EditMenu',
                    params: { id: item.id, brand: brand_name }
                  }"
                >
                  編集
                  <!-- <v-icon>mdi-pencil</v-icon> -->
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import MenuAPI from '@/services/menu'
import CategoryAPI from '@/services/category'
import { mapState } from 'vuex'
var moment = require('moment')
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  components: {
    Treeselect
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: 'ホーム',
          disabled: false,
          to: { name: 'Dashboard' }
          // exact: true
        },
        {
          text: 'メニュー',
          disabled: false,
          to: { name: 'Menu' },
          exact: true
        }
      ],
      totalMenus: 0,
      menus: [],
      categories: [
        {
          id: '',
          label: '全て'
        },
        {
          id: 'main',
          label: 'Main'
        }
      ],
      category: this.$store.getters.filters?.category || null,
      statuses: [
        { text: '全て', value: '' },
        { text: '表示中', value: 'active' },
        { text: '非表示中', value: 'inactive' },
        { text: 'ドラフト', value: 'draft' }
      ],
      status: this.$store.getters.filters?.status || null,
      loading: true,
      options: {},
      headers: [
        {
          text: '#',
          align: 'start',
          value: 'id'
        },
        { text: '', value: '' },
        { text: 'メニュー名', value: 'name_jp' },
        { text: '料金', value: 'price' },
        { text: 'カテゴリ', value: 'category_name' },
        { text: '表示優先', value: 'display_order' },
        { text: 'ステータス', value: 'status' },
        { text: '更新日', value: 'status' },
        { text: 'アクション', value: 'actions', sortable: false }
      ],
      brand_id: null,
      brand_name: this.$route.params.brand
    }
  },

  watch: {
    options: function(newVal, oldVal) {
      this.getMenuFromApi()
    },
    '$route.params': function(newVal, oldVal) {
      this.getMenuFromApi()
    },
    category: function(newVal, oldVal) {
      this.options.page = 1

      this.$store.commit('setFilter', { category: newVal })
      this.getMenuFromApi({
        category: newVal,
        shop_id: this.brand_id
      })
    },
    status: function(newVal, oldVal) {
      this.$store.commit('setFilter', { status: newVal })
      this.getMenuFromApi({ status: newVal, shop_id: this.brand_id })
    }
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name !== 'EditMenu') {
        vm.$store.commit('resetFilter')
        vm.category = null
        vm.status = null
      }
      next()
    })
  },

  beforeMount() {
    this.brand_id = this.$store.getters.brands.find(
      x => x.name === this.$route.params.brand
    ).id
    this.getCategories()
  },

  mounted() {
    if (this.alert != null) {
      setTimeout(() => {
        this.$store.commit('resetAlert')
      }, 5000)
    }
  },

  methods: {
    moment: function() {
      return moment()
    },
    getCategories() {
      CategoryAPI.getCategoriesTree(this.brand_id)
        .then(result => {
          var parsedobj = JSON.parse(JSON.stringify(result.data.data))
          this.categories = this.categories.concat(parsedobj)
        })
        .catch(err => {
          console.log(err)
        })
    },

    getMenuFromApi() {
      this.loading = true

      MenuAPI.getMenus(this.reqParams).then(res => {
        this.menus = res.data.data.data ? res.data.data.data : res.data.data
        this.totalMenus = res.data.data.total
        this.loading = false
      })
    }
  },

  computed: {
    ...mapState(['alert']),
    reqParams() {
      return {
        shop_id: this.brand_id,
        itemsPerPage: this.options.itemsPerPage,
        page: this.options.page,
        sortBy: this.options.sortBy[0] || null,
        sortDesc: this.options.sortDesc[0],
        category: this.category,
        status: this.status
      }
    }
  }
}
</script>

<style scoped>
.submenu >>> span {
  display: block;
}
.category-tree >>> .vue-treeselect__control {
  height: 40px !important;
}

.category-tree >>> .vue-treeselect__option--highlight {
  background-color: #26520e33 !important;
}

.v-text-field--outlined >>> fieldset {
  border-color: #ddd;
}
</style>
