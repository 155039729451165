<template>
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>

    <v-card :loading="loading" class="mx-5 my-12" v-if="menu">
      <template slot="progress">
        <v-progress-linear
          color="deep-purple"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>

      <v-card-title
        >{{ menu.name_jp + ' (' + menu.name_en + ' )' }}

        <div class="ml-auto">
          <v-btn small class="mr-4" :to="{ name: 'Menu' }"> 戻る </v-btn>

          <v-btn
            small
            color="secondary white--text"
            :to="{
              name: 'EditMenu',
              params: { id: menu.id, brand: brand_name }
            }"
          >
            編集
          </v-btn>
        </div>
      </v-card-title>

      <v-card-text>
        <v-chip
          v-if="menu.category"
          class="ma-2"
          color="primary"
          label
          text-color="white"
        >
          <v-icon left> mdi-label </v-icon>
          {{ menu.category.name_jp }}
        </v-chip>

        <div class="mt-4" v-html="menu.description"></div>
      </v-card-text>

      <v-divider class="mx-4"></v-divider>

      <v-card-title>価格</v-card-title>

      <v-card-text>
        <v-chip-group
          active-class="deep-purple accent-4 white--text"
          column
          v-if="menu.has_submenu"
        >
          <v-chip v-for="(submenu, i) in menu.submenu" :key="i">
            $ {{ submenu.price }} | {{ submenu.description }}
          </v-chip>
        </v-chip-group>
        <span v-else>{{ menu.price }}</span>
      </v-card-text>

      <v-divider class="mx-4"></v-divider>

      <v-card-title>詳細情報</v-card-title>

      <v-card-text class="px-10">
        <v-row>
          <v-col>
            <v-row>
              <v-col class="black--text" cols="4">スラグ</v-col>
              <v-col>{{ menu.slug }}</v-col>
            </v-row>
            <v-row>
              <v-col class="black--text" cols="4">表示優先</v-col>
              <v-col>{{ menu.display_order }}</v-col>
            </v-row>

            <v-row>
              <v-col class="black--text" cols="4">アピールポイント</v-col>
              <v-col>{{ menu.appealing_text }}</v-col>
            </v-row>

            <v-row>
              <v-col class="black--text" cols="4">予約情報</v-col>
              <v-col>{{ menu.booking_info }}</v-col>
            </v-row>

            <v-row>
              <v-col class="black--text" cols="4">コメント</v-col>
              <v-col>{{ menu.comment }}</v-col>
            </v-row>

            <v-row>
              <v-col class="black--text" cols="4">メタデスクリプション</v-col>
              <v-col>{{ menu.meta_description }}</v-col>
            </v-row>

            <v-row>
              <v-col class="black--text" cols="4">品数</v-col>
              <v-col>{{ menu.no_of_dishes }}</v-col>
            </v-row>

            <v-row>
              <v-col class="black--text" cols="4">詳細画面あり</v-col>
              <v-col>{{ menu.has_detail_page ? 'Yes' : 'No' }}</v-col>
            </v-row>

            <v-row>
              <v-col class="black--text" cols="4">Topページに表示</v-col>
              <v-col>{{ menu.show_in_homepage ? 'Yes' : 'No' }}</v-col>
            </v-row>

            <v-row>
              <v-col class="black--text" cols="4">ステータス</v-col>
              <v-col>{{ menu.status ? 'Active' : 'Inactive' }}</v-col>
            </v-row>
          </v-col>

          <v-col cols="4">
            <vue-upload-multiple-image
              v-if="images.length > 0"
              :data-images="images"
              :maxImage="maxImages"
              :showEdit="false"
              :showDelete="false"
              :showAdd="false"
              :disabled="true"
              :showPrimary="false"
            ></vue-upload-multiple-image>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import MenuAPI from '@/services/menu'
import VueUploadMultipleImage from 'vue-upload-multiple-image'

export default {
  components: {
    VueUploadMultipleImage
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: 'ホーム',
          disabled: false,
          to: { name: 'Dashboard' }
        },
        {
          text: 'メニュー',
          disabled: false,
          to: { name: 'Menu' },
          exact: true
        },
        {
          text: '料理／コース詳細',
          disabled: false,
          to: { name: 'MenuDetail' },
          exact: true
        }
      ],
      menu: null,
      items: [
        {
          src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg'
        },
        {
          src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg'
        },
        {
          src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg'
        },
        {
          src: 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg'
        }
      ],
      loading: false,
      brand_id: null,
      brand_name: this.$route.params.brand,
      images: [],
      maxImages: 8
    }
  },
  methods: {
    getMenuDetails(id) {
      this.loading = true
      MenuAPI.getMenu(id)
        .then(response => {
          this.loading = false
          this.menu = response.data.data
          this.setImages(response.data.data.images)
        })
        .catch(e => {
          console.log(e)
        })
    },
    setImages(images) {
      this.images = images.map(function(val, index) {
        return {
          path: process.env.VUE_APP_ASSET_URL + 'images/menus/' + val.image,
          default: val.is_main,
          highlight: val.is_main,
          name: val.image
        }
      })
      if (!this.menu.has_detail_page) {
        this.url =
          process.env.VUE_APP_ASSET_URL + 'images/menus/' + images[0].image
      }
    }
  },
  mounted() {
    this.getMenuDetails(this.$route.params.id)
  }
}
</script>

<style scoped>
/* >>>.image-container{
  width: 400px;
  height: 400px;
} */
</style>
