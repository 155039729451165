<template>
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-card elevation="2" class="pa-5 mt-5">
      <v-card-title>
        Create Brand
      </v-card-title>

      <v-card-text>
        <v-alert v-if="alert" :type="status" :value="alert" dismissible text>
          {{ statusMessage }}
        </v-alert>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              ref="name"
              v-model="name"
              :rules="rules.name"
              :error-messages="errorMessages"
              label="Brand name"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-textarea
              ref="phone"
              v-model="phone"
              :rules="rules.phone"
              rows="1"
              auto-grow
              label="Phone"
              id="phone"
              :value="phone"
            ></v-textarea>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              ref="postcode"
              v-model="postcode"
              :rules="rules.postcode"
              :error-messages="errorMessages"
              label="Postal code"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-textarea
              ref="prefecture"
              v-model="prefecture"
              :rules="rules.prefecture"
              rows="1"
              auto-grow
              label="Prefecture"
              id="prefecture"
              :value="prefecture"
            ></v-textarea>
          </v-col>
          <v-col cols="12" sm="4">
            <v-textarea
              ref="city"
              v-model="city"
              :rules="rules.city"
              rows="1"
              auto-grow
              label="City"
              id="city"
              :value="city"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider class="mt-12"></v-divider>
      <v-card-actions>
        <v-btn text :to="{ name: 'Brand' }">
          Cancel
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="submit"
          :loading="loading"
          :disabled="loading"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import BrandAPI from '@/services/brand'
export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: 'ホーム',
          disabled: false,
          to: { name: 'Dashboard' }
        },
        {
          text: 'Shop-info',
          disabled: true
        },
        {
          text: 'Brand',
          disabled: false,
          to: { name: 'Brand' },
          exact: true
        },
        {
          text: 'Create',
          disabled: false,
          to: { name: 'CreateBrand' },
          exact: true
        }
      ],
      name: null,
      phone: null,
      postcode: null,
      prefecture: null,
      city: null,
      alert: false,
      rules: {
        name: [v => !!v || 'Brand name は必須項目です'],
        phone: [v => !!v || 'Phone は必須項目です'],
        postcode: [v => !!v || 'Postal は必須項目です'],
        prefecture: [v => !!v || 'Prefecture は必須項目です'],
        city: [v => !!v || 'City は必須項目です']
      },
      errorMessages: '',
      status: null,
      statusMessage: null,
      loading: false,
      formHasErrors: false
    }
  },
  methods: {
    submit() {
      this.formHasErrors = false
      Object.keys(this.form).forEach(f => {
        if (!this.form[f]) this.formHasErrors = true
        if (f != 'content') {
          this.$refs[f].validate(true)
        }
      })
      if (!this.formHasErrors) {
        this.createBrand()
      }
    },
    createBrand() {
      this.loading = true
      BrandAPI.createBrand(this.form)
        .then(res => {
          this.status = res.data.status
          this.statusMessage = res.data.message
          this.resetForm()
          this.loading = false
          this.alert = true
          setTimeout(() => {
            this.alert = false
          }, 5000)
        })
        .catch(e => {
          this.loading = false
          console.log(e)
        })
    },

    resetForm() {
      this.errorMessages = []
      Object.keys(this.form).forEach(f => {
        this.$refs[f].reset()
      })
    }
  },

  computed: {
    form() {
      return {
        name: this.name,
        phone: this.phone,
        postcode: this.postcode,
        prefecture: this.prefecture,
        city: this.city
      }
    }
  }
}
</script>
