<template>
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>

    <!-- section one -->
    <v-card elevation="2" class="pa-5 mt-5">
      <v-card-title>
        ジョブ編集
      </v-card-title>
      <v-card-text>
        <v-alert v-if="alert" :type="alertStatus" dismissible text>
          {{ alertMessage }}
        </v-alert>

        <v-row>
          <!-- Left section -->
          <v-col>
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field
                  ref="title"
                  v-model="recruit.title"
                  :rules="rules.title"
                  :error-messages="errorMessages.title"
                  label="求人タイトル"
                  counter
                  maxlength="35"
                  required
                ></v-text-field>
              </v-col>

              <!-- <v-col cols="12" sm="12">
                <v-text-field
                  ref="heading"
                  v-model="heading"
                  :rules="rules.heading"
                  :error-messages="errorMessages.heading"
                  label="Heading"
                  required
                ></v-text-field>
              </v-col> -->

              <v-col cols="12" sm="12">
                <v-select
                  :items="shops"
                  item-text="name"
                  item-value="id"
                  ref="branch_id"
                  @change="changeBranch()"
                  v-model="recruit.branch_id"
                  :error-messages="errorMessages.branch_id"
                  label="募集店舗"
                  multiple
                >
                </v-select>
              </v-col>

              <v-col cols="12" sm="12">
                <v-text-field
                  class="mt-2"
                  ref="open_position"
                  v-model="recruit.open_position"
                  placeholder="募集職種"
                  counter
                  maxlength="20"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12">
                <v-select
                  :items="jobopening_items"
                  ref="job_opening"
                  v-model="recruit.job_opening"
                  label="雇用形態"
                >
                </v-select>
              </v-col>

              <v-col cols="12" sm="12">
                <v-select
                  v-model="recruit.openings"
                  ref="openings"
                  :items="openings_items"
                  label="募集人数"
                >
                </v-select>
              </v-col>

              <!-- <v-col cols="12" sm="12">
                <h2 style="font-size:1.2rem">求人概要</h2>
                <v-text-field
                  class="mt-2"
                  v-model="recruit.summary"
                  ref="summary"
                  counter
                  maxlength="100"
                ></v-text-field>
              </v-col> -->

              <v-col cols="12" sm="12">
                <vue-editor
                  v-model="recruit.content"
                  ref="content"
                  :editorToolbar="customToolbar"
                  :error-messages="errorMessages.content"
                  placeholder="仕事内容・応募条件"
                ></vue-editor>
              </v-col>
            </v-row>
          </v-col>

          <!-- Right section -->
          <v-col>
            <div class="image-section">
              <vue-upload-multiple-image
                @upload-success="uploadImageSuccess"
                @before-remove="beforeRemove"
                @edit-image="editImage"
                :data-images="images"
                idUpload="myIdUpload"
                editUpload="myIdEdit"
                dragText="画像をアップロード"
                browseText=""
                :showPrimary="showPrimary"
                popupText="選択した画像がメインになります。"
                :maxImage="maxImages"
                :multiple="multiUpload"
              ></vue-upload-multiple-image>
              <p class="recommended-image">推奨する画像のサイズ : 縦 300 横 300</p>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider class="mt-12"></v-divider>
    </v-card>

    <!--section two  -->
    <v-card elevation="2" class="pa-5 mt-5">
      <v-card-title>
        <h2 style="font-size:1.5rem; font-style:bold" class="section-heading">
          給与・雇用条件
        </h2>
      </v-card-title>

      <v-card-text>
        <v-alert v-if="alert" :type="alertStatus" dismissible text>
          {{ alertMessage }}
        </v-alert>
        <v-row>

          <v-col cols="12" sm="12">
            <v-select
              :items="payment_type_items"
              ref="payment_type"
              v-model="recruit.payment_type"
              label="給与形態"
            >
            </v-select>
          </v-col>

          <v-col cols="12" sm="12">
            <v-row>
              <v-col cols="6" md="6" sm="12">
                <v-text-field
                  ref="basics1"
                  v-model="recruit.basics1"
                  placeholder="給料"
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="6" sm="12">
                <v-text-field
                  ref="basics2"
                  v-model="recruit.basics2"
                  placeholder="給料備考 1"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" sm="12">
            <v-text-field
              ref="additional_info"
              v-model="recruit.additional_info"
              placeholder=" 給料備考 2"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="12">
            <!-- multiselect benefits -->
            <h2 style="font-size:1.2rem">
              待遇
            </h2>
            <v-autocomplete
              ref="benefits"
              v-model="recruit.benefits"
              :items="benefits_items"
              item-text="name"
              item-value="id"
              multiple
              deletable-chips
              hide-selected
              persistent-hint
              small-chips
              solo
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="12">
            <v-textarea
              ref="shift_details"
              v-model="recruit.shift_details"
              solo
              label="シフト詳細"
            ></v-textarea>
          </v-col>

        </v-row>
      </v-card-text>
      <v-divider class="mt-12"></v-divider>
    </v-card>
    <!--section two end  -->

    <!--section three start  -->
    <v-card elevation="2" class="pa-5 mt-5">
      <v-card-title>
        <h2 style="font-size:1.5rem; font-style:bold" class="section-heading">
          休日・休暇・応募条件
        </h2>
      </v-card-title>

      <v-card-text>
        <v-alert v-if="alert" :type="alertStatus" dismissible text>
          {{ alertMessage }}
        </v-alert>
        <v-row>
          <v-col cols="12" sm="12">
            <!-- Multiselct holidays conditions -->
            <h2 style="font-size:1.2rem">休日・休暇</h2>
            <v-autocomplete
              ref="holidays"
              v-model="recruit.holidays"
              :items="holiday_items"
              item-text="name"
              item-value="id"
              clearable
              hide-selected
              multiple
              persistent-hint
              small-chips
              solo
            ></v-autocomplete>
          </v-col>
<!-- 
          <v-col cols="12" sm="12">
            <h2 style="font-size:1.2rem" class="section-heading">応募条件</h2>
          </v-col> -->

          <!-- <v-col cols="12" sm="12">
            <h2 style="font-size:1.2rem">応募条件</h2>
            <v-textarea
              ref="application_conditions"
              v-model="recruit.application_conditions"
              solo
              name="input-7-4"
              label="応募条件"
            ></v-textarea>
          </v-col> -->

          <v-col cols="12" sm="12">
            <v-text-field
              ref="contact_info"
              v-model="recruit.contact_info"
              placeholder="連絡先"
            ></v-text-field>
          </v-col>

        </v-row>
      </v-card-text>
      <v-divider class="mt-12"></v-divider>

      <v-card-actions class="card-actions">
        <v-btn small :to="{ name: 'Recruit' }">
          戻る
        </v-btn>

        <v-btn small color="red" @click="deleteItem(recruit)">
          削除
        </v-btn>

        <v-btn
          color="secondary white--text"
          small
          @click="submit"
          :loading="loading"
          :disabled="loading"
        >
          更新
        </v-btn>
      </v-card-actions>
    </v-card>
    <!--section three end  -->
  </div>
</template>

<script>
import RecruitAPI from '@/services/recruit'
import VueUploadMultipleImage from 'vue-upload-multiple-image'
import axios from 'axios'
import ShopAPI from '@/services/shop'

import { VueEditor } from 'vue2-editor'

export default {
  components: {
    VueUploadMultipleImage,
    VueEditor
  },
  data() {
    return {
      customToolbar: [
        [{ header: [1, 2, 3, false] }],
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }]
      ],
      breadcrumbs: [
        {
          text: 'ホーム',
          disabled: false,
          to: { name: 'Dashboard' }
        },
        {
          text: '求人',
          disabled: false,
          to: { name: 'Recruit' },
          exact: true
        },
        {
          text: '編集',
          disabled: false,
          to: { name: 'EditRecruit' },
          exact: true
        }
      ],

      images: [],
      fileList: [],
      maxImages: 8,
      shops: [],

      recruit: {
        title: null,
        heading: null,
        open_position: null,
        job_opening: null,
        openings: null,
        // summary: null,
        basics1: null,
        basics2: null,
        additional_info: null,
        benefits: [],
        shift_details: null,
        contact_info: null,
        other_conditions: null,
        holidays: [],
        other_holidays: null,
        // application_conditions: null,
        content: null,
        status: true,
        branch_id: null,
        payment_type: null,
      },

      alert: false,
      rules: {
        title: [v => !!v || '求人タイトル は必須項目です']
      },
      errorMessages: '',
      alertStatus: null,
      alertMessage: null,
      loading: false,
      formHasErrors: false,
      brand_id: null,
      jobopening_items: ['パート・アルバイト', '正社員', '研修生'],
      openings_items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      payment_type_items: ['月給', '時給'],
      benefits_items: [],
      holiday_items: [],
      multiUpload: false,
      showPrimary: false
    }
  },

  mounted() {
    this.getRecruitebenefits()
    this.getRecruitHolidays()
  },
  methods: {
    getShops() {
      ShopAPI.getShops({
        shop_id: this.brand_id,
        isLessData: true
      }).then(res => {
        this.shops.push({id:'all', name: '全ての店舗'})
        this.shops.push(...res.data.data)
      })
    },
    changeBranch() {
      if (this.recruit.branch_id.includes('all')) {
        this.recruit.branch_id = this.shops.map((shop) => {
          return shop.id;
        })
      }
    },
    getRecruitebenefits() {
      axios.get('/recruite-benefits').then(res => {
        this.benefits_items = res.data.data
      })
    },
    getRecruitHolidays() {
      axios.get('/recruite-holidays').then(res => {
        this.holiday_items = res.data.data
      })
    },
    uploadImageSuccess(formData, index, fileList) {
      this.fileList = fileList
    },
    beforeRemove(index, done, fileList) {
      var r = confirm('remove image')
      if (r == true) {
        done()
      } else {
      }
    },
    editImage(formData, index, fileList) {
      this.fileList = fileList
    },
    deleteItem(item) {
      this.$swal
        .fire({
          title: '本当に削除してもよろしいですか？',
          text: '一度削除すると元に戻せません。',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '削除',
          cancelButtonText: '戻る'
        })
        .then(result => {
          if (result.isConfirmed) {
            RecruitAPI.deleteRecruit(item.id)
              .then(response => {
                this.loading = false
                let payload = {
                  status: response.data.status,
                  message: '削除完了'
                }
                this.$store.commit('setAlert', payload)
                this.$router.push({
                  name: 'Recruit',
                  params: { id: this.$route.params.brand }
                })
              })
              .catch(e => {
                this.$swal.fire(
                  'Server error',
                  'Oops! something wrong, please try again later',
                  'error'
                )
              })
          }
        })
    },
    resetForm() {
      this.errorMessages = []
      Object.keys(this.form).forEach(f => {
        if (f != 'fileList') {
          this.$refs[f].reset()
        }
      })
    },
    setImages(images) {
      this.images = images.map(function(val, index) {
        return {
          path: process.env.VUE_APP_ASSET_URL + 'images/recruit/' + val.image,
          default: val.is_main,
          highlight: val.is_main,
          name: val.image
        }
      })
    },
    getRecruitDetails(id) {
      this.loading = true
      RecruitAPI.getRecruit(id)
        .then(response => {
          this.loading = false
          this.recruit = response.data.data
          this.setImages(response.data.data.images)
          this.status = this.status == 1 ? true : false
          this.recruit.branch_id = this.recruit.branches.map(function(branch) {
            return branch.id
          })
        })
        .catch(e => {
          this.loading = false
          console.log(e)
        })
    },
    submit() {
      this.formHasErrors = false

      Object.keys(this.rules).forEach(f => {
        console.log(f)
        let success = this.$refs[f].validate(true)
        if (!success) {
          this.formHasErrors = true
        }
      })
      if (!this.formHasErrors) {
        this.updateRecruit()
      }
    },
    updateRecruit() {
      this.loading = true

      RecruitAPI.updateRecruit(this.recruit.id, this.formData)
        .then(response => {
          this.loading = false
          let payload = {
            status: response.data.status,
            message: '更新が完了しました'
          }
          this.$store.commit('setAlert', payload)
          this.$router.push({
            name: 'Recruit',
            params: { id: this.$route.params.brand }
          })
        })
        .catch(e => {
          this.loading = false
          console.log(e)
        })
    }
  },
  beforeMount() {
    this.brand_id = this.$store.getters.brands.find(
      x => x.name === this.$route.params.brand
    ).id
    this.getRecruitDetails(this.$route.params.id)
    this.getShops()
  },

  computed: {
    benefitIds() {
      if (this.benefits) {
        return this.benefits.map((value, key) => {
          return value.id
        })
      } else {
        return ''
      }
    },
    holidayIds() {
      if (this.holidays) {
        return this.holidays.map((value, key) => {
          return value.id
        })
      } else {
        return ''
      }
    },
    form() {
      return {
        title: this.recruit.title,
        open_position: this.recruit.open_position,
        job_opening: this.recruit.job_opening,
        openings: this.recruit.openings,
        // summary: this.recruit.summary,
        basics1: this.recruit.basics1,
        basics2: this.recruit.basics2,
        additional_info: this.recruit.additional_info,
        shift_details: this.recruit.shift_details,
        contact_info: this.recruit.contact_info,
        other_conditions: this.recruit.other_conditions,
        other_holidays: this.recruit.other_holidays,
        // application_conditions: this.recruit.application_conditions,
        content: this.recruit.content,
        payment_type: this.recruit.payment_type,
      }
    },
    formData() {
      var formData = new FormData()

      Object.keys(this.form).forEach(key => {
        if (!(this.form[key] == null || this.form[key] === '')) {
          formData.append(key, this.form[key])
        }
      })
      // formData.append('shop_id', this.$route.params.brand)
      formData.append('fileList', JSON.stringify(this.fileList))
      formData.append('shop_id', this.brand_id)
      formData.append('branch_id', JSON.stringify(this.recruit.branch_id))
      formData.append('benefits', JSON.stringify(this.recruit.benefits))
      formData.append('holidays', JSON.stringify(this.recruit.holidays))
      formData.append('_method', 'put')

      return formData
    }
  }
}
</script>

<style scoped>
#my-strictly-unique-vue-upload-multiple-image {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
.image-section >>> .image-container,
.image-section >>> .image-list-container {
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  max-width: 300px;
}
.section-heading::before {
  content: '';
  margin-right: 10px;
  border-left: 5px solid #bc3c39;
}
.recommended-image {
  text-align: center;
}
</style>
