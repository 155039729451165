import axios from 'axios'
export default {
  createBrand(params) {
    return axios.post('shops', params)
  },
  getBrands() {
    return axios.get('shops').then(res => {
      Object.entries(res.data.data.data).forEach(brand => {
        brand[1].status = brand[1].status == 1 ? '表示中' : '非表示'
      })
      return res
    })
  },
  getBrand(id) {
    return axios.get('shops/' + id)
  },
  updateBrand(id, params) {
    return axios.put('shops/' + id, params)
  },
  deleteBrand(id) {
    return axios.delete('shops/' + id)
  }
}
