<template>
  <v-navigation-drawer
    app
    clipped
    v-model="DRAWER_STATE"
    :mini-variant="!DRAWER_STATE"
    :width="user && user.user_type == 'subadmin' ? '240' : '290'"
    :permanent="$vuetify.breakpoint.mdAndUp"
    :temporary="$vuetify.breakpoint.smAndDown"
    :mini-variant-width="sidebarMinWidth"
    :class="{ 'drawer-mini': !DRAWER_STATE }"
    @transitionend="menuBarClicked"
  >
    <v-list nav dense>
      <template v-for="(item, i) in items">
        <v-row
          v-if="item.heading && DRAWER_STATE"
          :key="item.heading"
          align="center"
        >
          <v-col cols="6">
            <span
              style="padding-left: 32px"
              class="text-body-1 subheader"
              :class="item.heading && DRAWER_STATE ? 'show ' : 'hide'"
            >
              {{ item.heading }}
            </span>
          </v-col>
          <v-col cols="6" class="text-center"> </v-col>
        </v-row>
        <v-divider
          v-else-if="item.divider"
          :key="i"
          dark
          class="my-1"
        ></v-divider>

        <div v-else-if="item.dynamic" :key="item.role">
          <div
            v-if="user && item.role == user.user_type && item.role == 'admin'"
          >
            <v-list-group
              color="primary"
              v-for="brand in brands"
              :key="brand.id"
              v-model="brand.model"
            >
              <template v-slot:prependIcon>
                <v-icon color="iconColor" size="20">{{ brand.icon }}</v-icon>
              </template>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title class="grey-darken-1--text">
                    {{ brand.name_sidebar ? brand.name_sidebar : brand.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>

              <v-subheader class="menu-subheader" v-if="!DRAWER_STATE"
                ><strong>{{ brand.name }}</strong></v-subheader
              >

              <template v-for="(child, i) in brand.children">

                <template v-if="child.link == 'Dashboard'">

                  <v-list-item
                    :key="i"
                    :href="getBrandUrl(brand)"
                    link
                    :target="child.targt"
                    @click="itemClicked"
                    v-click-outside="onClickOutsideItem"
                  >
                    <v-list-item-action class="child-menu" v-if="child.icon">
                      <v-icon size="20" color="iconColor">{{ child.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title class="grey-darken-1--text">
                        {{ child.title }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                </template>  

                <template v-else>

                  <v-list-item
                    :key="i"
                    :to="{ name: child.link, params: { brand: brand.name } }"
                    link
                    @click="itemClicked"
                    v-click-outside="onClickOutsideItem"
                  >
                    <v-list-item-action class="child-menu" v-if="child.icon">
                      <v-icon size="20" color="iconColor">{{ child.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title class="grey-darken-1--text">
                        {{ child.title }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                </template>  

              </template>


            </v-list-group>
          </div>
          <div
            v-else-if="
              user && item.role == user.user_type && item.role == 'subadmin'
            "
          >
            <!-- <v-list-group
              color="primary"
              v-for="(menu, i) in item.menus"
              :key="i"
              v-model="menu.model"
            >
              <template v-slot:prependIcon>
                <v-icon color="iconColor" size="20">{{ menu.icon }}</v-icon>
              </template>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title class="grey-darken-1--text">
                    {{ menu.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </template> -->
              <v-list-item
                v-for="(child, i) in item.menus"
                :key="i"
                :to="{ name: child.link, params: { brand: brands[0].name } }"
                link
              >
                <v-list-item-action v-if="child.icon">
                  <v-icon color="iconColor" size="20">{{ child.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="grey-darken-1--text">
                    {{ child.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            <!-- </v-list-group> -->
          </div>
        </div>

        <v-list-group
          color="primary"
          v-else-if="item.children"
          :key="item.title"
          v-model="item.model"
        >
          <template v-slot:prependIcon>
            <v-icon color="iconColor" size="20">{{ item.icon }}</v-icon>
          </template>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="grey-darken-1--text">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            v-for="(child, i) in item.children"
            :key="i"
            :to="child.link"
            link
          >
            <v-list-item-action v-if="child.icon">
              <!-- <v-icon size="20">A</v-icon> -->
              <span>{{ child.iconText }}</span>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="grey-darken-1--text">
                {{ child.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item
          color="primary"
          v-else-if="item.link"
          :key="item.text"
          :to="item.link === '#' ? null : item.link"
          link
        >
          <v-list-item-action>
            <v-icon size="20" color="iconColor">{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="grey-darken-1--text" link>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  props: {
    source: String
  },
  data() {
    return {
      items: [
        // { title: 'ダッシュボード', icon: 'mdi-home', link: '/dashboard' },
        {
          dynamic: true,
          role: 'admin',
          icon: 'mdi-silverware-fork-knife',
          children: [
            {
              title: 'お知らせ',
              icon: 'mdi-newspaper-variant',
              iconText: 'R1',
              link: 'News',
              targt: '_self'
            },
            {
              title: 'メニュー',
              icon: 'mdi-glass-wine',
              iconText: 'R2',
              link: 'Menu',
              targt: '_self'
            },
            {
              title: '採用',
              icon: 'mdi-badge-account-outline',
              iconText: 'R3',
              link: 'Recruit',
              targt: '_self'
            },
            {
              title: '店舗',
              icon: 'mdi-storefront-outline',
              iconText: 'R4',
              link: 'Shop',
              targt: '_self'
            },
            {
              title: 'メニューカテゴリ管理',
              icon: 'mdi-grid-large',
              iconText: 'R5',
              link: 'Category',
              targt: '_self'
            },
            {
              title: '消費税設定',
              icon: 'mdi-cog',
              iconText: 'R5',
              link: 'Tax',
              targt: '_self'
            },
            {
              title: 'ブランドサイト',
              icon: 'mdi-desktop-mac',
              iconText: 'R6',
              link: 'Dashboard',
              targt: '_blank'
            }
          ]
        },
        {
          dynamic: true,
          role: 'subadmin',
          menus: [
            {
              title: 'お知らせ',
              icon: 'mdi-information-outline',
              link: 'News',
              // model: false,
              // children: [
              //   {
              //     title: 'List',
              //     icon: 'mdi-circle-small',
              //     iconText: 'I1',
              //     link: 'News'
              //   },
              //   {
              //     title: 'Create',
              //     icon: 'mdi-circle-small',
              //     iconText: 'I2',
              //     link: 'CreateNews'
              //   }
              // ]
            },
            {
              title: 'メニュー',
              icon: 'mdi-menu',
              link: 'Menu',
              // model: false,
              // children: [
              //   {
              //     title: 'List',
              //     icon: 'mdi-circle-small',
              //     iconText: 'M1',
              //     link: 'Menu'
              //   },
              //   {
              //     title: 'Create',
              //     icon: 'mdi-circle-small',
              //     iconText: 'M2',
              //     link: 'CreateMenu'
              //   }
              // ]
            },
            {
              title: '採用',
              icon: 'mdi-account-tie',
              link: 'Recruit',
              // model: false,
              // children: [
              //   {
              //     title: 'List',
              //     icon: 'mdi-circle-small',
              //     iconText: 'R1',
              //     link: 'Recruit'
              //   },
              //   {
              //     title: 'Create',
              //     icon: 'mdi-circle-small',
              //     iconText: 'R2',
              //     link: 'CreateRecruit'
              //   }
              // ]
            },
            {
              title: '店舗',
              icon: 'mdi-office-building-outline',
              link: 'Shop',
              // model: false,
              // children: [
              //   {
              //     title: '店舗一覧',
              //     icon: 'mdi-circle-small',
              //     iconText: 'S1',
              //     link: 'Shop'
              //   }
              // ]
            }
          ]
        },

        { divider: true },
        // { heading: '各種設定' },
        {
          title: '管理者管理',
          icon: 'mdi-account-group',
          iconText: 'SA',
          link: '/admins'
        }
      ],
      // sidebarWidth: 240,
      sidebarMinWidth: 76
    }
  },
  computed: {
    ...mapState({
      drawer: state => state.drawer,
      brands: state => state.auth.brands,
      user: state => state.auth.user
    }),
    DRAWER_STATE: {
      get() {
        return this.drawer
      },
      set(newValue) {
        if (newValue === this.drawer) return
        this.TOGGLE_DRAWER()
      }
    }
  },
  methods: {
    ...mapActions(['TOGGLE_DRAWER']),
    itemClicked() {
      if (this.DRAWER_STATE == false)
        Object.keys(this.brands).forEach(v => (this.brands[v].model = false))
    },
    menuBarClicked() {
      if (this.DRAWER_STATE == false)
        Object.keys(this.brands).forEach(v => (this.brands[v].model = false))
    },
    onClickOutsideItem() {
      // if(this.DRAWER_STATE == false)
      // Object.keys(this.brands).forEach(v => this.brands[v].model = false)
    },
    getBrandUrl(brand){
      if(brand.id == '1'){        
        return process.env.VUE_APP_NIKUMAN_URL
      } else if(brand.id == '2'){
        return process.env.VUE_APP_DALIAN_URL
      } else {
        return '';
      }
    }
  },
  created() {
    Object.keys(this.brands).forEach(v => {
      if (v == '0') {
        this.brands[v].icon = 'mdi-silverware-fork-knife'
        this.brands[v].name_sidebar = 'TOKYO PAO'
        this.brands[v].children = [
            {
              title: 'NEWS',
              icon: 'mdi-newspaper-variant',
              iconText: 'R1',
              link: 'News',
              targt: '_self'
            },
            {
              title: 'PRODUCTS',
              icon: 'mdi-glass-wine',
              iconText: 'R2',
              link: 'Menu',
              targt: '_self'
            },
            {
              title: 'RECRUIT',
              icon: 'mdi-badge-account-outline',
              iconText: 'R3',
              link: 'Recruit',
              targt: '_self'
            },
            {
              title: 'SHOP',
              icon: 'mdi-storefront-outline',
              iconText: 'R4',
              link: 'Shop',
              targt: '_self'
            },
            {
              title: 'CATEGORY',
              icon: 'mdi-grid-large',
              iconText: 'R5',
              link: 'Category',
              targt: '_self'
            },
            {
              title: '消費税設定',
              icon: 'mdi-cog',
              iconText: 'R5',
              link: 'Tax',
              targt: '_self'
            },
            {
              title: 'ブランドサイト',
              icon: 'mdi-desktop-mac',
              iconText: 'R6',
              link: 'Dashboard',
              targt: '_blank'
            }
          ]
      } else if (v == '1') {
        this.brands[v].icon = 'mdi-food'
        this.brands[v].children = [
            {
              title: 'お知らせ',
              icon: 'mdi-newspaper-variant',
              iconText: 'R1',
              link: 'News',
              targt: '_self'
            },
            {
              title: 'メニュー',
              icon: 'mdi-glass-wine',
              iconText: 'R2',
              link: 'Menu',
              targt: '_self'
            },
            {
              title: '採用',
              icon: 'mdi-badge-account-outline',
              iconText: 'R3',
              link: 'Recruit',
              targt: '_self'
            },
            {
              title: '店舗',
              icon: 'mdi-storefront-outline',
              iconText: 'R4',
              link: 'Shop',
              targt: '_self'
            },
            {
              title: 'メニューカテゴリ管理',
              icon: 'mdi-grid-large',
              iconText: 'R5',
              link: 'Category',
              targt: '_self'
            },
            {
              title: '消費税設定',
              icon: 'mdi-cog',
              iconText: 'R5',
              link: 'Tax',
              targt: '_self'
            },
            {
              title: 'ブランドサイト',
              icon: 'mdi-desktop-mac',
              iconText: 'R6',
              link: 'Dashboard',
              targt: '_blank'
            }
          ]
      } else {
        this.brands[v].icon = 'mdi-food'
      }
    })
  }
}
</script>

<style src="./Sidebar.scss" lang="scss" />

<style scoped>
.child-menu {
  margin-left: 20px;
  margin-right: 0px !important;
}

.drawer-mini {
  overflow: inherit;
}

.drawer-mini >>> .v-list-group__items {
  width: 200px;
  position: absolute;
  background: white;
  margin-left: 68px;
  margin-top: -40px;
  border: thin solid rgba(0, 0, 0, 0.12);
  border-radius: 4%;
  background: #fafafa;
}

.drawer-mini >>> .v-list-group .v-list-group__items .v-list-item__content {
  width: auto;
  height: auto;
  clip: inherit;
}

.drawer-mini >>> .v-list-group .v-list-group__items .v-list-item__action {
  display: none;
}

.drawer-mini >>> .v-list-group:hover .v-list-group__items {
  display: block;
}

.drawer-mini >>> .v-list-group .v-list-group__items .v-list-item {
  justify-content: flex-start !important;
  margin-left: 10px;
}

.menu-subheader {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
</style>
