<template>
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>

    <v-card elevation="2" class="pa-5 mt-5">
      <v-card-title>
        カテゴリを編集
      </v-card-title>

      <div v-if="category">
        <v-card-text>
          <v-row>
            <v-col>
              <v-radio-group v-model="category.type" row>
                <v-radio label="詳細画面なし" value="common"></v-radio>
                <v-radio label="詳細画面あり" value="course"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <div v-if="category.type == 'common' && brand_id == 2">
            <treeselect
              v-model="category.parent_id"
              :multiple="false"
              placeholder="選択"
              noChildrenText="該当項目が見つかりません。"
              noOptionsText="該当オプションがありません。"
              noResultsText="結果が見つかりません"
              :options="options"
            />
            <!-- <v-row v-for="(items, index) in categories" :key="index">
              <v-col md="6">
                <v-select
                  ref="parent_id"
                  v-model="items.parent_id"
                  v-on:change="checkCategory(items.parent_id, index)"
                  :items="items"
                  item-value="id"
                  item-text="name"
                  label="Category"
                  return-object
                />
              </v-col>
            </v-row> -->
          </div>

          <v-row>
            <v-col cols="12" md="6"
              ><v-text-field
                v-model="category.name_jp"
                ref="name_jp"
                :rules="rules.name_jp"
                label="カテゴリ名"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6"
              ><v-text-field
                v-if="brand_id == 2"
                v-model="category.name_en"
                ref="name_en"
                :rules="rules.name_en"
                label="英語表記"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6"
              ><v-text-field
                v-model="category.slug"
                ref="slug"
                :rules="rules.slug"
                label="スラグ"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6"
              ><v-text-field
                v-model="category.display_order"
                ref="display_order"
                :rules="rules.display_order"
                label="表示順"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col v-if="category.type == 'common'" cols="12" md="12">
              <v-textarea
                ref="description"
                v-model="category.description"
                rows="1"
                auto-grow
                label="カテゴリーコメント"
                id="description"
              ></v-textarea>
            </v-col>
            <v-col v-if="category.type == 'course'" cols="12" md="12">
              <v-textarea
                ref="appealing_text"
                v-model="category.appealing_text"
                rows="1"
                auto-grow
                label="カテゴリーコメント"
                id="appealing_text"
              ></v-textarea>
            </v-col>
          </v-row>

          <div class="d-flex">
            <v-img :src="url" max-height="150" max-width="250"></v-img>
            <v-btn v-if="url" text class="ml-2" @click="removeImage">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <v-file-input
            v-if="brand_id == 2"
            @change="previewImage"
            ref="image"
            v-model="category.image"
            :rules="rules.image"
            label="画像"
            :clearable="false"
          >
          </v-file-input>

          <v-switch
            v-model="category.status"
            ref="status"
            label="表示/非表示"
          ></v-switch>

          <v-divider class="mt-12"></v-divider>
          <v-card-actions>
            <v-btn small :to="{ name: 'Category' }">
              戻る
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary white--text"
              small
              @click="submit"
              :loading="loading"
              :disabled="loading"
            >
              更新
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </div>
    </v-card>
  </div>
</template>

<script>
import CategoryAPI from '@/services/category'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
export default {
  components: {
    Treeselect
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: 'ホーム',
          disabled: false,
          to: { name: 'Dashboard' }
        },
        {
          text: 'カテゴリ',
          disabled: false,
          to: { name: 'Category' },
          exact: true
        },
        {
          text: '編集',
          disabled: false,
          to: { name: 'EditCategory' },
          exact: true
        }
      ],
      options: [],
      rules: {
        name_jp: [v => !!v || 'カテゴリ名 は必須項目です'],
        // name_en: [v => !!v || '英語表記 は必須項目です'],
        slug: [v => !!v || 'スラグ は必須項目です'],
        // image: [
        //   v => !v || v.size < 2000000 || 'Image size should be less than 2 MB!'
        // ]
      },
      categories: [],
      main_categories: [],
      loading: false,
      category: null,
      errorMessages: '',
      url: null,
      brand_id: null,
      image_removed: false,
    }
  },

  mounted() {
    this.brand_id = this.$store.getters.brands.find(
      x => x.name === this.$route.params.brand
    ).id
    this.getCategoryDetails(this.$route.params.id)
    this.getCategories()
  },

  methods: {
    getCategoryDetails(id) {
      this.loading = true
      CategoryAPI.getCategory(id)
        .then(response => {
          this.loading = false
          this.category = response.data.data
          if (this.category.image) {
            this.url =
              process.env.VUE_APP_ASSET_URL +
              'images/categories/' +
              this.category.image
          }
          this.category.image = null
          this.category.status = this.category.status == 1 ? true : false
        })
        .catch(e => {
          this.loading = false
          console.log(e)
        })
    },

    removeImage() {
      this.url = null
      this.category.image = null
      this.image_removed = true
    },

    getCategories() {
      CategoryAPI.getCategoriesTree(this.brand_id)
        .then(result => {
          var parsedobj = JSON.parse(JSON.stringify(result.data.data))

          this.options = parsedobj
        })
        .catch(err => {
          console.log(err)
        })
    },

    // getMainCategories() {
    //   CategoryAPI.getCategories({
    //     shop_id: this.brand_id,
    //     parent_id: 'null'
    //   })
    //     .then(result => {
    //       let resultArray = [{ id: null, name: 'Main Category' }]
    //       result.data.data.map(function(item, key) {
    //         if (item.name != '') {
    //           resultArray.push({
    //             id: item.id,
    //             name: item.name_jp,
    //             parent_id: item.parent_id
    //           })
    //         }
    //       })
    //       this.main_categories = resultArray
    //       this.categories.push(this.main_categories)
    //     })
    //     .catch(err => {
    //       console.log(err)
    //     })
    // },
    checkCategory(category, index) {
      this.categories.length = index + 1
      if (!category.id) {
        this.category.parent_id = null
        return
      }
      this.category.parent_id = category.id
      CategoryAPI.getChildren(category.id)
        .then(result => {
          let resultArray = []
          result.data.data.data.map(function(item, key) {
            if (item.name != '') {
              resultArray.push({
                id: item.id,
                name: item.name_jp,
                parent_id: item.parent_id
              })
            }
          })
          if (resultArray.length > 0) {
            this.categories.push(resultArray)
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    submit() {
      this.formHasErrors = false
      Object.keys(this.rules).forEach(f => {
        let success = this.$refs[f].validate(true)
        if (!success) {
          this.formHasErrors = true
        }
      })
      if (!this.formHasErrors) {
        this.updateCategory()
      }
    },

    previewImage() {
      if (this.category.image) {
        this.image_removed = false
        this.url = URL.createObjectURL(this.category.image)
      }
    },

    updateCategory() {
      this.loading = true
      CategoryAPI.updateCategory(this.category.id, this.formData)
        .then(response => {
          this.loading = false
          let payload = {
            status: response.data.status,
            message: '更新が完了しました'
          }
          this.$store.commit('setAlert', payload)
          this.$router.push({
            name: 'Category',
            params: { id: this.$route.params.brand }
          })
        })
        .catch(e => {
          this.loading = false
          console.log(e)
        })
    }
  },

  computed: {
    form() {
      return {
        type: this.category.type,
        parent_id: this.category.parent_id,
        name_en: this.category.name_en,
        name_jp: this.category.name_jp,
        slug: this.category.slug,
        display_order: this.category.display_order,
        image: this.category.image,
        image_removed: this.image_removed,
        status: this.category.status ? 1 : 0,
        description: this.category.type == 'common' ? this.category.description : '',
        appealing_text: this.category.type == 'course' ? this.category.appealing_text : ''
      }
    },
    formData() {
      var formData = new FormData()
      Object.keys(this.form).forEach(key => {
        if (!(this.form[key] == null || this.form[key] === '')) {
          formData.append(key, this.form[key])
        }
      })
      formData.append('shop_id', this.brand_id)
      formData.append('_method', 'put')
      return formData
    }
  }
}
</script>
