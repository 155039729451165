import axios from 'axios'

export default {
  sendResetLink(email) {
    return axios.post('password/email', { email: email })
  },
  reset(params) {
    return axios.post('password/reset', params)
  },
  changePassword(params) {
    return axios.put('change-password', params)
  }
}
