import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import config from '@/config'

Vue.use(Vuetify)
import ja from '@/plugins/ja_vuetify'

const opts = {
  icons: {
    iconfont: 'mdi' // default - only for display purposes
  },
  theme: {
    themes: {
      light: config.light
    }
  },
  lang: {
    locales: { ja },
    current: 'ja'
  }
}

export default new Vuetify(opts)
