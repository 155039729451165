<template>
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-card elevation="2" class="pa-5 mt-5">
      <v-card-title>
        店舗編集
      </v-card-title>

      <div v-if="shop">
        <v-card-text>
          <v-row>
            <v-col>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-text-field
                    ref="name"
                    v-model="shop.name"
                    :rules="rules.name"
                    :error-messages="errorMessages"
                    label="店舗名"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    ref="postcode"
                    v-model="shop.postcode"
                    :rules="rules.postcode"
                    :error-messages="errorMessages"
                    label="郵便番号"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    ref="prefecture"
                    v-model="shop.prefecture"
                    :rules="rules.prefecture"
                    label="都道府県"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field
                    ref="city"
                    v-model="shop.city"
                    :rules="rules.city"
                    label="市町村以下"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-text-field
                    ref="phone"
                    v-model="shop.phone"
                    :rules="rules.phone"
                    label="電話番号"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-textarea
                    ref="access"
                    v-model="shop.access"
                    :rules="rules.access"
                    rows="1"
                    auto-grow
                    label="アクセス"
                    id="access"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" sm="12">
                  <!-- <vue-editor
                    v-model="shop.business_hours"
                    ref="business_hours"
                    label="営業日"
                    :editorToolbar="customToolbar"
                    :rules="rules.business_hours"
                    id="business_hours"
                  ></vue-editor> -->
                  <v-textarea
                    ref="business_hours"
                    v-model="shop.business_hours"
                    :rules="rules.business_hours"
                    rows="1"
                    auto-grow
                    label="営業日"
                  ></v-textarea>
                </v-col>

                <v-col v-if="brand_id == 2" cols="12" sm="12">
                  <v-textarea
                    ref="holidays"
                    v-model="shop.holidays"
                    :rules="rules.holidays"
                    rows="1"
                    auto-grow
                    label="定休日"
                    id="holidays"
                  ></v-textarea>
                </v-col>

                <v-col v-if="brand_id == 2" cols="12" sm="12">
                  <v-textarea
                    ref="shop_introduction"
                    v-model="shop.shop_introduction"
                    :rules="rules.shop_introduction"
                    rows="1"
                    auto-grow
                    label="店舗紹介"
                    id="shop_introduction"
                  ></v-textarea>
                </v-col>
                
                <v-col v-if="brand_id == 1" cols="12" sm="12">
                  <v-text-field
                    ref="email"
                    v-model="shop.email"
                    label="Email"
                    required
                  ></v-text-field>
                </v-col>
                <!-- <v-col cols="12" sm="6">
                  <v-textarea
                    ref="reservation"
                    v-model="shop.reservation"
                    :rules="rules.reservation"
                    rows="1"
                    auto-grow
                    label="予約サイトのURL"
                    id="reservation"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-file-input
                    label="アイコンアップロード"
                    ref="reservation_icon"
                    v-model="shop.reservation_icon"
                  ></v-file-input>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-textarea
                    ref="delivery"
                    :rules="rules.delivery"
                    v-model="shop.delivery"
                    rows="1"
                    auto-grow
                    label="配送"
                    id="delivery"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-file-input
                    ref="delivery_icon"
                    label="アイコンアップロード"
                    v-model="shop.delivery_icon"
                  ></v-file-input>
                </v-col> -->
</v-row>
          </v-col>
                <v-col>
              <div class="image-section">
                <vue-upload-multiple-image
                  @upload-success="uploadImageSuccess"
                  @before-remove="beforeRemove"
                  @edit-image="editImage"
                  :data-images="images"
                  idUpload="myIdUpload"
                  editUpload="myIdEdit"
                  dragText="画像をアップロード"
                  browseText=""
                  primaryText="デフォルト"
                  :showPrimary="false"
                  popupText="選択した画像がメインになります。"
                  markIsPrimaryText="メイン画像に設定する"
                  :maxImage="maxImages"
                ></vue-upload-multiple-image>
                <!-- <p class="recommended-image">推奨する画像のサイズ : 縦 800 横 500</p> -->
              </div>
            </v-col>
        <v-col cols="12">
            <v-row>
              <template v-if="brand_id == 2">
                <template v-for="(reservationItem, counter) in reservation">
                  <div class="col-11 reservation-wrapper" v-bind:key="counter">
                    <v-col cols="12" sm="5">
                      <v-textarea
                        v-model="reservationItem.url"
                        :rules="rules.reservation"
                        rows="1"
                        auto-grow
                        label="予約サイトのURL"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12" sm="5">
                      <v-file-input
                        label="アイコンアップロード"
                        v-model="reservationItem.icon"
                      ></v-file-input>
                    </v-col>
                    <v-col cols="12" sm="1">
                      <v-icon v-if="reservationItem.id==0" @click="deleteReservation(counter)">mdi-delete</v-icon>
                      <v-icon v-else @click="deleteReservationData(reservationItem.id, counter)">mdi-delete</v-icon>
                    </v-col>
                    <v-col cols="12" sm="1">
                      <v-img
                          :lazy-src="imgpath+reservationItem.image_path"
                          :src="imgpath+reservationItem.image_path"
                      ></v-img>
                    </v-col>
                  </div>              
                </template>

                <v-col cols="1 align-center d-flex flex-wrap-reverse">
                  <v-btn x-small @click="addMoreReservation">
                    Add More
                  </v-btn>         
                </v-col>   


                <template v-for="(deliveryItem, counter) in delivery">
                  <div class="col-11 delivery-wrapper" v-bind:key="counter">
                    <v-col cols="12" sm="5">
                      <v-textarea
                        v-model="deliveryItem.url"
                        :rules="rules.delivery"
                        rows="1"
                        auto-grow
                        label="デリバリーサービスサイトのURL"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12" sm="5">
                      <v-file-input
                        label="アイコンアップロード"
                        v-model="deliveryItem.icon"
                      ></v-file-input>
                    </v-col>
                    <v-col cols="12" sm="1">
                      <v-icon v-if="deliveryItem.id==0" @click="deleteDelivery(counter)">mdi-delete</v-icon>
                      <v-icon v-else @click="deleteDeliveryData(deliveryItem.id, counter)">mdi-delete</v-icon>
                    </v-col>
                    <v-col cols="12" sm="1">
                      <v-img
                        :lazy-src="imgpath+deliveryItem.image_path"
                        :src="imgpath+deliveryItem.image_path"
                      ></v-img>
                    </v-col>
                  </div>              
                </template>

                <v-col cols="1 align-center d-flex flex-wrap-reverse">
                  <v-btn x-small @click="addMoreDelivery">
                    Add More
                  </v-btn>         
                </v-col>
              </template>

                <v-col>
                  <v-switch
                    ref="status"
                    v-model="shop.status"
                    label="表示/非表示"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider class="mt-12"></v-divider>

        <v-card-actions class="card-actions">
          <v-btn small :to="{ name: 'Shop' }">
            戻る
          </v-btn>

          <v-btn small color="red" @click="deleteItem(shop)">
            削除
          </v-btn>

          <v-btn
            color="secondary white--text"
            small
            @click="submit"
            :loading="loading"
            :disabled="loading"
          >
            更新
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </div>
</template>

<script>
import ShopAPI from '@/services/shop'
import VueUploadMultipleImage from 'vue-upload-multiple-image'
// import { VueEditor } from 'vue2-editor'

export default {
  components: {
    // VueEditor,
    VueUploadMultipleImage
  },
  data() {
    return {
      imgpath: process.env.VUE_APP_ASSET_URL + 'images/branches/',
      breadcrumbs: [
        {
          text: 'ホーム',
          disabled: false,
          to: { name: 'Dashboard' }
        },
        {
          text: '店舗',
          disabled: false,
          to: { name: 'Shop' },
          exact: true
        },
        {
          text: '編集',
          disabled: false,
          to: { name: 'EditShop' },
          exact: true
        }
      ],
      // customToolbar: [
      //   [{ header: [1, 2, 3, false] }],
      //   ['bold', 'italic', 'underline'],
      //   [{ list: 'ordered' }, { list: 'bullet' }],
      // ],
      rules: {
        name: [v => !!v || '店舗名 は必須項目です'],
        phone: [v => !!v || '電話番号 は必須項目です'],
        postcode: [v => !!v || '郵便番号 は必須項目です'],
        prefecture: [v => !!v || '都道府県 は必須項目です'],
        city: [v => !!v || '市町村以下 は必須項目です'],
        access: [v => !!v || 'アクセス は必須項目です'],
      //   business_hours: [v => !!v || '営業日 は必須項目です'],
      //   holidays: [v => !!v || '定休日 は必須項目です'],
      //  shop_introduction: [v => !!v || '店舗紹介 は必須項目です'],
       // reservation: [v => !!v || '予約は必須です'],
       // delivery: [v => !!v || '配送は必須アイテムです']
      },
      shop: null,
      errorMessages: '',
      images: [],
      fileList: [],
      maxImages: 8,
      brand_id: null,
      delivery: [
        {
          url: null,
          icon: null,
        },
      ],
      reservation: [
        {
          url: null,
          icon: null,
        },
      ],
    }
  },

  mounted() {
    this.getShopDetails(this.$route.params.id)
  },

  methods: {
    deleteReservationData (dataid, counter) {
      this.$swal
      .fire({
        title: '本当に削除してもよろしいですか？',
        text: '一度削除すると元に戻せません。',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '削除',
        cancelButtonText: '戻る'
      })
      .then(result => {
        if (result.isConfirmed) {
          ShopAPI.deleteReservation(dataid).then(e=> e.data).then(e=>{
            this.reservation.splice(counter,1);
          });
        }
      });
    },
    deleteDeliveryData (dataid, counter) {
      this.$swal
      .fire({
        title: '本当に削除してもよろしいですか？',
        text: '一度削除すると元に戻せません。',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '削除',
        cancelButtonText: '戻る'
      })
      .then(result => {
        if (result.isConfirmed) {
          ShopAPI.deleteDelivery(dataid).then(e=> e.data).then(e=>{
            this.delivery.splice(counter,1);
          });
        }
      });
    },
    deleteDelivery(counter){
      if(this.delivery.length > 1)
      this.delivery.splice(counter,1);
    },    
    addMoreDelivery(){
      this.delivery.push({
        url: null,
        icon: null,
        id:null,
      })
    },
    deleteReservation(counter){
      if(this.reservation.length > 1)
      this.reservation.splice(counter,1);
    },    
    addMoreReservation(){
      this.reservation.push({
        url: null,
        icon: null,
        id:null,
      })
    },
    uploadImageSuccess(formData, index, fileList) {
      this.fileList = fileList
    },
    beforeRemove(index, done, fileList) {
      var r = confirm('remove image')
      if (r == true) {
        done()
      } else {
      }
    },
    editImage(formData, index, fileList) {
      this.fileList = fileList
    },
    setImages(images) {
      this.images = images.map(function(val, index) {
        return {
          path: process.env.VUE_APP_ASSET_URL + 'images/branches/' + val.image,
          default: val.is_main,
          highlight: val.is_main,
          name: val.image
        }

      })
    },
    getShopDetails(id) {
      this.loading = true
      ShopAPI.getShop(id)
        .then(response => {
          this.loading = false
          this.shop = response.data.data
          
          if(response.data.data.reservations.length > 0)
            this.reservation = response.data.data.reservations
          
          if(response.data.data.delivery.length > 0)
            this.delivery = response.data.data.delivery

          this.shop.status = this.shop.status == 1 ? true : false
          this.setImages(response.data.data.images)
        })
        .catch(e => {
          this.loading = false
          console.log(e)
        })
    },
    submit() {
      let formHasErrors = false

      Object.keys(this.rules).forEach(f => {
        if (this.form[f] === null) formHasErrors = true
        this.$refs[f].validate(true)
      })
      formHasErrors=false;
      if (!formHasErrors) {
        this.updateShop()
      }
    },

    updateShop() {
      this.loading = true
      this.formData = this.getformdata()
      this.formData.append('fileList', JSON.stringify(this.fileList))
      for(let i=0; i < this.delivery.length; i++){
        if (this.delivery[i].url) {
          this.formData.append(`delivery_url[]`, this.delivery[i].url)
        }
        
        if(this.delivery[i].id){
          this.formData.append(`delivery_id[]`, this.delivery[i].id)
        }else{
          this.formData.append(`delivery_id[]`, 0)
        }
        
        if(this.delivery[i].icon){
          this.formData.append(`delivery_image_${i}`, this.delivery[i].icon)
        }
      }
      for(let i=0; i < this.reservation.length; i++){
        if (this.reservation[i].url) {
          this.formData.append(`reservation_url[]`, this.reservation[i].url)
        }

        if(this.reservation[i].id){
          this.formData.append(`reservation_id[]`, this.reservation[i].id)
        }else{
          this.formData.append(`reservation_id[]`, 0)
        }
        
        if(this.reservation[i].icon){
          this.formData.append(`reservation_image_${i}`, this.reservation[i].icon)
        }
      }
      ShopAPI.updateShop(this.shop.id, this.formData)
        .then(response => {
          this.loading = false
          let payload = {
            status: response.data.status,
            message: '更新が完了しました'
          }
          this.$store.commit('setAlert', payload)
          this.$router.push({
            name: 'Shop',
            params: { id: this.$route.params.brand }
          })
        })
        .catch(e => {
          this.loading = false
          console.log(e)
        })
    },

    deleteItem(item) {
      this.$swal
        .fire({
          title: '本当に削除してもよろしいですか？',
          text: '一度削除すると元に戻せません。',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '削除',
          cancelButtonText: '戻る'
        })
        .then(result => {
          if (result.isConfirmed) {
            ShopAPI.deleteShop(item.id)
              .then(response => {
                this.loading = false
                let payload = {
                  status: response.data.status,
                  message: '削除完了'
                }
                this.$store.commit('setAlert', payload)
                this.$router.push({
                  name: 'Shop',
                  params: { id: this.$route.params.brand }
                })
              })
              .catch(e => {
                this.$swal.fire(
                  'Server error',
                  'Oops! something wrong, please try again later',
                  'error'
                )
              })
          }
        })
    },
    getformdata(){
      var formData = new FormData()
      formData.append('name', this.shop.name)
      formData.append('phone', this.shop.phone)
      formData.append('postcode', this.shop.postcode)
      formData.append('prefecture', this.shop.prefecture)
      formData.append('city', this.shop.city)
      formData.append('business_hours', this.shop.business_hours)
      formData.append('access', this.shop.access)
      if (this.brand_id == 2) {
        formData.append('holidays', this.shop.holidays)
        formData.append('shop_introduction', this.shop.shop_introduction)
        formData.append('reservation_icon', this.shop.reservation_icon)
        formData.append('delivery_icon', this.shop.delivery_icon)
        formData.append('reservation', this.shop.reservation)
        formData.append('delivery', this.shop.delivery)
      }
      formData.append('status', this.shop.status ? 1 : 0)
      formData.append('_method', 'put')
      return formData
    }
  },

  beforeMount() {
    this.brand_id = this.$store.getters.brands.find(
      (x) => x.name === this.$route.params.brand
    ).id
  },

  computed: {
    form() {
      let arr = {
        name: this.name,
        phone: this.phone,
        postcode: this.postcode,
        prefecture: this.prefecture,
        city: this.city,
        business_hours: this.business_hours,
        status: this.status ? 1 : 0,
      }
      arr.access = this.access
      if (this.brand_id == 2) {
        arr.holidays = this.holidays
        arr.shop_introduction = this.shop_introduction
        arr.reservation_icon = this.reservation_icon
      } else {
        arr.email = this.email
      }

      return arr
    },
    formData() {
      var formData = new FormData()
      formData.append('name', this.shop.name)
      formData.append('phone', this.shop.phone)
      formData.append('postcode', this.shop.postcode)
      formData.append('prefecture', this.shop.prefecture)
      formData.append('city', this.shop.city)
      
      formData.append('access', this.shop.access)
      if (this.brand_id == 2) {
        formData.append('holidays', this.shop.holidays)
        formData.append('shop_introduction', this.shop.shop_introduction)
        formData.append('reservation', this.shop.reservation)
        formData.append('reservation_icon', this.shop.reservation_icon)
        formData.append('delivery', this.shop.delivery)
        formData.append('delivery_icon', this.shop.delivery_icon)
      }

      formData.append('business_hours', this.shop.business_hours)
      formData.append('status', this.shop.status ? 1 : 0)
      formData.append('email', this.shop.email)
      formData.append('_method', 'put')
      return formData
    }
  },
}
</script>

<style scoped>

.delivery-wrapper{
  width: 100%;
  display: flex;
}

.reservation-wrapper{
  width: 100%;
  display: flex;
}

.mdi-delete{
  margin-top: 28px;
  cursor: pointer;
}

#my-strictly-unique-vue-upload-multiple-image {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
.image-section >>> .image-container,
.image-section >>> .image-list-container {
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  max-width: 300px;
}
.recommended-image {
  text-align: center;
}
</style>