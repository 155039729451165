<template>
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-card elevation="2" class="pa-5 mt-5">
      <v-card-title> 店舗登録 </v-card-title>

      <v-card-text>
        <v-alert v-if="alert" :type="alertStatus" dismissible text>
          {{ alertMessage }}
        </v-alert>

        <v-row>
          <v-col>
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field
                  ref="name"
                  v-model="name"
                  :rules="rules.name"
                  :error-messages="errorMessages.name"
                  label="店舗名"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  ref="postcode"
                  v-model="postcode"
                  :rules="rules.postcode"
                  :error-messages="errorMessages.postcode"
                  label="郵便番号"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  ref="prefecture"
                  v-model="prefecture"
                  :rules="rules.prefecture"
                  :error-messages="errorMessages.prefecture"
                  rows="1"
                  auto-grow
                  label="都道府県"
                  id="prefecture"
                  :value="prefecture"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  ref="city"
                  v-model="city"
                  :rules="rules.city"
                  rows="1"
                  auto-grow
                  label="市町村以下"
                  id="city"
                  :value="city"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field
                  ref="phone"
                  v-model="phone"
                  :rules="rules.phone"
                  rows="1"
                  auto-grow
                  label="電話番号"
                  id="phone"
                  :value="phone"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
                <v-textarea
                  ref="access"
                  v-model="access"
                  :rules="rules.access"
                  rows="1"
                  auto-grow
                  label="アクセス"
                  id="access"
                  :value="access"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="12">
                営業日
                <!-- <vue-editor
                  v-model="business_hours"
                  ref="business_hours"
                  label="営業日"
                  :editorToolbar="customToolbar"
                  :rules="rules.business_hours"
                ></vue-editor> -->
                <v-textarea
                  v-model="business_hours"
                  ref="business_hours"
                  label="営業日"
                  :rules="rules.business_hours"
                  rows="1"
                  auto-grow
                  :value="business_hours"
                ></v-textarea>
              </v-col>

              <v-col v-if="brand_id == 2" cols="12" sm="12">
                <v-textarea
                  ref="holidays"
                  v-model="holidays"
                  :rules="rules.holidays"
                  rows="1"
                  auto-grow
                  label="定休日"
                  id="holidays"
                  :value="holidays"
                ></v-textarea>
              </v-col>

              <v-col v-if="brand_id == 2" cols="12" sm="12">
                <v-textarea
                  ref="shop_introduction"
                  v-model="shop_introduction"
                  :rules="rules.shop_introduction"
                  rows="1"
                  auto-grow
                  label="店舗紹介"
                  id="shop_introduction"
                  :value="shop_introduction"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field
                  ref="email"
                  v-model="email"
                  rows="1"
                  auto-grow
                  label="Email"
                  id="email"
                  :value="email"
                ></v-text-field>
              </v-col>
              <!-- <v-col cols="12" sm="6">
                <v-textarea
                  ref="reservation"
                  v-model="reservation"
                  :rules="rules.reservation"
                  rows="1"
                  auto-grow
                  label="ご予約"
                  id="reservation"
                  :value="reservation"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="6">
                <v-file-input
                  label="アイコンアップロード"
                  ref="reservation_icon"
                  v-model="reservation_icon"
                  :rules="rules.icon"
                ></v-file-input>
              </v-col> -->
            </v-row>
          </v-col>
          <v-col>
            <div class="image-section">
              <vue-upload-multiple-image
                @upload-success="uploadImageSuccess"
                @before-remove="beforeRemove"
                @edit-image="editImage"
                :data-images="images"
                idUpload="myIdUpload"
                editUpload="myIdEdit"
                dragText="画像をアップロード"
                browseText=""
                primaryText="デフォルト"
                :showPrimary="false"
                popupText="選択した画像がメインになります。"
                markIsPrimaryText="メイン画像に設定する"
                :maxImage="maxImages"
              ></vue-upload-multiple-image>
              <!-- <p class="recommended-image">推奨する画像のサイズ : 縦 800 横 500</p> -->
            </div>
          </v-col>

          <v-col cols="12">
            <v-row>
              <template v-if="brand_id == 2">
                <template v-for="(reservationItem, counter) in reservation">
                  <div class="reservation-wrapper" v-bind:key="counter">
                    <v-col cols="12" sm="6">
                      <v-textarea
                        v-model="reservationItem.url"
                        rows="1"
                        auto-grow
                        label="予約サイトのURL"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12" sm="5">
                      <v-file-input
                        label="アイコンアップロード"
                        v-model="reservationItem.icon"
                      ></v-file-input>
                    </v-col>
                    <v-col cols="12" sm="1">
                      <v-icon @click="deleteReservation(counter)">mdi-delete</v-icon>
                    </v-col>    
                  </div>              
                </template>

                <v-col cols="12 text-right mt-n6">
                  <v-btn x-small @click="addMoreReservation">
                    Add More
                  </v-btn>         
                </v-col>   


                <template v-for="(deliveryItem, counter) in delivery">
                  <div class="delivery-wrapper" v-bind:key="counter">
                    <v-col cols="12" sm="6">
                      <v-textarea
                        v-model="deliveryItem.url"
                        rows="1"
                        auto-grow
                        label="デリバリーサービスサイトのURL"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12" sm="5">
                      <v-file-input
                        label="アイコンアップロード"
                        v-model="deliveryItem.icon"
                      ></v-file-input>
                    </v-col>
                    <v-col cols="12" sm="1">
                      <v-icon @click="deleteDelivery(counter)">mdi-delete</v-icon>
                    </v-col>    
                  </div>              
                </template>

                <v-col cols="12 text-right mt-n6">
                  <v-btn x-small @click="addMoreDelivery">
                    Add More
                  </v-btn>         
                </v-col>
              </template>
              <v-col cols="12">
                <v-switch
                  ref="status"
                  v-model="status"
                  label="表示/非表示"
                ></v-switch>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider class="mt-12"></v-divider>
      <v-card-actions class="card-actions">
        <v-btn small :to="{ name: 'Category' }"> 戻る </v-btn>
        <v-btn
          color="secondary white--text"
          small
          @click="submit"
          :loading="loading"
          :disabled="loading"
        >
          更新
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import ShopAPI from '@/services/shop'
import VueUploadMultipleImage from 'vue-upload-multiple-image'
// import { VueEditor } from 'vue2-editor'

export default {
  components: {
    // VueEditor,
    VueUploadMultipleImage,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: 'ホーム',
          disabled: false,
          to: { name: 'Dashboard' },
        },
        {
          text: '店舗',
          disabled: false,
          to: { name: 'Shop' },
          exact: true,
        },
        {
          text: '新規作成',
          disabled: false,
          to: { name: 'CreateShop' },
          exact: true,
        },
      ],
      images: [],
      fileList: [],
      maxImages: 8,
      name: null,
      phone: null,
      postcode: null,
      prefecture: null,
      city: null,
      access: null,
      business_hours: null,
      holidays: null,
      shop_introduction: null,
      email: null,
      delivery: [
        {
          url: null,
          icon: null,
        },
      ],
      reservation: [
        {
          url: null,
          icon: null,
        },
      ],      
      status: true,
      alert: false,
      rules: {
        name: [(v) => !!v || '店舗名 は必須項目です'],
        phone: [(v) => !!v || '電話番号 は必須項目です'],
        postcode: [(v) => !!v || '郵便番号 は必須項目です'],
        prefecture: [(v) => !!v || '都道府県 は必須項目です'],
        city: [(v) => !!v || '市町村以下 は必須項目です'],
        access: [(v) => !!v || 'アクセス は必須項目です'],
        // business_hours: [(v) => !!v || '営業日 は必須項目です'],
        // holidays: [(v) => !!v || '定休日 は必須項目です'],
        // shop_introduction: [(v) => !!v || '店舗紹介 は必須項目です'],
        // reservation: [(v) => !!v || '予約は必須です'],
        // icon: [(v) => !!v || '必須'],
      },
      // customToolbar: [
      //   [{ header: [1, 2, 3, false] }],
      //   ['bold', 'italic', 'underline'],
      //   [{ list: 'ordered' }, { list: 'bullet' }],
      // ],
      errorMessages: '',
      alertStatus: null,
      alertMessage: null,
      loading: false,
      formHasErrors: false,
      brand_id: null,
    }
  },

  beforeMount() {
    this.brand_id = this.$store.getters.brands.find(
      (x) => x.name === this.$route.params.brand
    ).id
  },

  methods: {
    deleteDelivery(counter){
      if(this.delivery.length > 1)
      this.delivery.splice(counter,1);
    },    
    addMoreDelivery(){
      this.delivery.push({
        url: null,
        icon: null
      })
    },
    deleteReservation(counter){
      if(this.reservation.length > 1)
      this.reservation.splice(counter,1);
    },    
    addMoreReservation(){
      this.reservation.push({
        url: null,
        icon: null
      })
    },    
    uploadImageSuccess(formData, index, fileList) {
      this.fileList = fileList
    },
    beforeRemove(index, done, fileList) {
      var r = confirm('remove image')
      if (r == true) {
        done()
      } else {
      }
    },
    editImage(formData, index, fileList) {},

    resetForm() {
      this.errorMessages = []
      Object.keys(this.form).forEach((f) => {
        if (f != 'fileList') {
          this.$refs[f].reset()
        }
      })
    },
    submit() {
      this.formHasErrors = false
      Object.keys(this.form).forEach((f) => {
        if (this.form[f] === null) this.formHasErrors = true
        // this.$refs[f].validate(true)
      })
      if (!this.formHasErrors) {
        this.createShop()
      }
    },
    createShop() {
      this.loading = true
      // console.log(this.formData.getAll());
      // return;
      ShopAPI.createShop(this.formData, this.brand_id)
        .then((res) => {
          this.loading = false
          let payload = {
            status: res.data.status,
            message: res.data.message
          }

          this.$store.commit('setAlert', payload)

          this.$router.push({
            name: 'Shop',
            params: { brand: this.$route.params.brand }
          })
        })
        .catch((e) => {
          this.loading = false
          this.errorMessages = e.data.errors
        })
    },
  },

  computed: {
    form() {
      let arr = {
        name: this.name,
        phone: this.phone,
        postcode: this.postcode,
        prefecture: this.prefecture,
        city: this.city,
        business_hours: this.business_hours,
        status: this.status ? 1 : 0,
      }
      arr.access = this.access
      if (this.brand_id == 2) {
        arr.holidays = this.holidays
        arr.shop_introduction = this.shop_introduction
        arr.reservation_icon = this.reservation_icon
      } else {
        arr.email = this.email
      }

      return arr
    },
    formData() {
      var formData = new FormData()
      Object.keys(this.form).forEach((key) => {
        if (!(this.form[key] == null || this.form[key] === '')) {
          formData.append(key, this.form[key])
        }
      })
      formData.append('shop_id', this.brand_id)
      formData.append('fileList', JSON.stringify(this.fileList));
      for(let i=0; i < this.delivery.length; i++){
        if(this.delivery[i].url){
          formData.append(`delivery_url[]`, this.delivery[i].url)
          formData.append(`delivery_image[]`, this.delivery[i].icon)
        }
      }
      for(let i=0; i < this.reservation.length; i++){
        if(this.reservation[i].url){
          formData.append(`reservation_url[]`, this.reservation[i].url)
          formData.append(`reservation_image[]`, this.reservation[i].icon)          
        }
      }
      // formData.append('reservation', JSON.stringify(this.reservation))

      return formData
    },
  },
}
</script>

<style scoped>

.delivery-wrapper{
  width: 100%;
  display: flex;
}

.reservation-wrapper{
  width: 100%;
  display: flex;
}

.mdi-delete{
  margin-top: 28px;
  cursor: pointer;
}

#my-strictly-unique-vue-upload-multiple-image {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
.image-section >>> .image-container,
.image-section >>> .image-list-container {
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  max-width: 300px;
}
.recommended-image {
  text-align: center;
}
</style>
