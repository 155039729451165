<template>
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>

    <v-alert v-if="alert" :type="alert.status" dismissible text>
      {{ alert.message }}
    </v-alert>

    <v-card elevation="2" class="pa-5 mt-5">
      <v-card-title>
        求人一覧
        <v-col class="text-right">
          <v-btn
            small
            color="secondary white--text"
            :to="{ name: 'CreateRecruit' }"
          >
            新規作成
          </v-btn>
        </v-col>
      </v-card-title>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="recruit"
          :options.sync="options"
          :server-items-length="totalRecruit"
          :loading="loading"
        >
          <template v-slot:[`item.index`]="{ item }">
            {{ recruit.indexOf(item) + 1 }}
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              x-small
              icon
              class="mr-2"
              :to="{
                name: 'EditRecruit',
                params: { id: item.id, brand: brand_name }
              }"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import RecruitAPI from '@/services/recruit'

export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: 'ホーム',
          disabled: false,
          to: { name: 'Dashboard' }
          // exact: true
        },
        {
          text: '求人',
          disabled: false,
          to: { name: 'Recruit' },
          exact: true
        }
      ],
      totalRecruit: 0,
      recruit: [],
      loading: true,
      options: {},
      headers: [
        {
          text: 'ID',
          align: 'start',
          // sortable: false,
          value: 'index'
        },

        { text: 'タイトル', value: 'title' },
        { text: '店舗名', value: 'shop_name' },
        { text: 'ステータス', value: 'status' },
        { text: '更新日', value: 'updated_at' },
        { text: 'アクション', value: 'actions', sortable: false }
      ],
      brand_id: null,
      brand_name: this.$route.params.brand
    }
  },

  watch: {
    options: function(newVal, oldVal) {
      this.getRecruitFromApi()
    },
    '$route.params': {
      handler() {
        this.getRecruitFromApi()
      }
    }
  },

  computed: {
    ...mapState(['alert']),
    reqParams() {
      return {
        shop_id: this.brand_id,
        itemsPerPage: this.options.itemsPerPage,
        page: this.options.page,
        sortBy: this.options.sortBy[0] || null,
        sortDesc: this.options.sortDesc[0]
      }
    }
  },

  mounted() {
    this.brand_id = this.$store.getters.brands.find(
      x => x.name === this.$route.params.brand
    ).id
    if (this.alert != null) {
      setTimeout(() => {
        this.$store.commit('resetAlert')
      }, 5000)
    }
  },

  methods: {
    getRecruitFromApi() {
      this.loading = true
      RecruitAPI.getRecruitList(this.reqParams).then(res => {
        this.recruit = res.data.data.data
        this.totalRecruit = res.data.data.total
        this.loading = false
      })
    }
  }
}
</script>
