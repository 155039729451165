import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index.js'

import Layout from '@/components/admin/layout/Layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/mtclsecuredlogin',
    name: 'Login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      public: true, // public => can access those pages without login
      guest: true, // guest => can only access those as a guest (like login page)
      title: '管理パネルにログイン'
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('@/views/auth/ForgotPassword.vue'),
    meta: {
      public: true,
      guest: true,
      title: 'パスワード再設定'
    }
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: () => import('@/views/auth/ResetPassword.vue'),
    meta: {
      public: true,
      guest: true,
      title: 'パスワード再設定'
    }
  },
  {
    path: '/',
    redirect: 'mtclsecuredlogin',
    name: 'Layout',
    component: Layout,
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () => import('@/views/dashboard/Dashboard.vue')
      },
      // news
      {
        path: '/:brand/news',
        name: 'News',
        component: () => import('@/views/news/NewsList.vue')
      },
      {
        path: '/:brand/news/create',
        name: 'CreateNews',
        component: () => import('@/views/news/NewsCreate.vue')
      },
      {
        path: '/:brand/news/edit/:id',
        name: 'EditNews',
        component: () => import('@/views/news/NewsEdit.vue')
      },

      // menu
      {
        path: '/:brand/menus',
        name: 'Menu',
        component: () => import('@/views/menu/MenuList.vue')
      },
      {
        path: '/:brand/menu/create',
        name: 'CreateMenu',
        component: () => import('@/views/menu/MenuCreate.vue')
      },
      {
        path: '/:brand/menu/edit/:id',
        name: 'EditMenu',
        component: () => import('@/views/menu/MenuEdit.vue')
      },
      {
        path: '/:brand/menu/:id',
        name: 'MenuDetail',
        component: () => import('@/views/menu/MenuDetail.vue')
      },

      // recruit
      {
        path: '/:brand/recruit',
        name: 'Recruit',
        component: () => import('@/views/recruit/RecruitList.vue')
      },
      {
        path: '/:brand/recruit/create',
        name: 'CreateRecruit',
        component: () => import('@/views/recruit/RecruitCreate.vue')
      },
      {
        path: '/:brand/recruit/edit/:id',
        name: 'EditRecruit',
        component: () => import('@/views/recruit/RecruitEdit.vue')
      },

      // shop news
      {
        path: '/:brand/store',
        name: 'Shop',
        component: () => import('@/views/shop/ShopList.vue')
      },
      {
        path: '/:brand/store/create',
        name: 'CreateShop',
        component: () => import('@/views/shop/ShopCreate.vue')
      },
      {
        path: '/:brand/store/edit/:id',
        name: 'EditShop',
        component: () => import('@/views/shop/ShopEdit.vue')
      },

      {
        path: 'brands',
        name: 'Brand',
        component: () => import('@/views/shop/brand/BrandList.vue')
      },

      {
        path: '/brand/create',
        name: 'CreateBrand',
        component: () => import('@/views/shop/brand/BrandCreate.vue')
      },
      {
        path: '/brand/edit/:id',
        name: 'EditBrand',
        component: () => import('@/views/shop/brand/BrandEdit.vue')
      },

      // settings
      {
        path: 'admins',
        name: 'Admin',
        component: () => import('@/views/setting/admin/AdminList.vue')
      },
      {
        path: '/admin/create',
        name: 'CreateAdmin',
        component: () => import('@/views/setting/admin/AdminCreate.vue')
      },
      {
        path: '/admin/edit/:id',
        name: 'EditAdmin',
        component: () => import('@/views/setting/admin/AdminEdit.vue')
      },
      {
        path: '/:brand/categories',
        name: 'Category',
        component: () => import('@/views/setting/category/CategoryList.vue')
      },
      {
        path: '/:brand/category/create',
        name: 'CreateCategory',
        component: () => import('@/views/setting/category/CategoryCreate.vue')
      },
      {
        path: '/:brand/category/edit/:id',
        name: 'EditCategory',
        component: () => import('@/views/setting/category/CategoryEdit.vue')
      },
      {
        path: '/:brand/tax',
        name: 'Tax',
        component: () => import('@/views/setting/tax/Tax.vue')
      },

      // guideline
      {
        path: 'change-password',
        name: 'ChangePassword',
        component: () => import('@/views/ChangePassword.vue')
      }
    ]
  },
  {
    path: '*',
    name: 'Error',
    component: () => import('@/views/error/Error.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

// Auth guard
router.beforeEach((to, from, next) => {
  const authRequired = !to.matched.some(record => record.meta.public)
  const loggedIn = store.getters.token
  if (authRequired && !loggedIn) {
    next({
      path: '/mtclsecuredlogin',
      query: { redirect: to.fullPath }
    })
  } else if (!authRequired && loggedIn) {
    if (store.getters.user.user_type == 'admin' || store.getters.user.shop_id == '2') {
      next('/大連餃子基地DALIAN/menus')
    } else {
      next('/プレミアム肉まん/menus')
    }
    return
  }
  next()
})

const DEFAULT_TITLE = '管理者 - Asian Table ブランド管理パネル'
router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE
  })
})

export default router
