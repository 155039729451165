<template>
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>

    <v-card elevation="2" class="pa-5 mt-5">
      <v-card-title>
        お知らせ投稿
      </v-card-title>

      <v-card-text>
        <v-alert v-if="alert" :type="status" :value="alert" dismissible text>
          {{ statusMessage }}
        </v-alert>

        <v-text-field
          v-model="news.title"
          ref="title"
          :error-messages="errorMessages.title"
          :rules="rules.title"
          label="タイトル"
        ></v-text-field>

        <label>内容</label>
        <vue-editor
          v-model="news.content"
          ref="content"
          class="mb-4"
          :editorToolbar="customToolbar"
          :error-messages="errorMessages.content"
        ></vue-editor>

        <v-autocomplete
          ref="brand_id"
          v-model="news.branch_id"
          :items="shops"
          item-text="name"
          item-value="id"
          @change="changeBranch()"
          :menu-props="{ maxHeight: '400' }"
          label="対象店舗"
          multiple
        ></v-autocomplete>

        <v-file-input
          @change="previewImage"
          v-model="news.image"
          ref="image"
          :rules="rules.image"
          :error-messages="errorMessages.image"
          show-size
          label="画像"
        >
        </v-file-input>
        <v-img :src="url" max-height="150" max-width="250"></v-img>
        <p class="recommended-image">推奨する画像のサイズ : 縦 800 横 500</p>

        <!-- <v-card class="py-3 px-6 image-upload" outlined tile :img="url">
          <label for="id-1-photo-file" class="photo-cm-blk id-card">
            <div class="new-upload" v-if="!url">
              <v-icon color="indigo">
                mdi-image-plus
              </v-icon>
              <p class="indigo--text">Upload</p>
              <p class="grey--text ext-info">
                PNG, JPG, GIF
              </p>
            </div>
          </label>

          <v-file-input
            @change="previewImage"
            class="d-none"
            v-model="news.image"
            ref="image"
            label="Image"
            id="id-1-photo-file"
          >
          </v-file-input>
        </v-card> -->
      </v-card-text>

      <v-divider class="mt-12"></v-divider>
      <v-card-actions class="card-actions">
        <v-btn small :to="{ name: 'News' }">
          戻る
        </v-btn>
        <v-btn
          color="secondary white--text"
          small
          @click="submit"
          :loading="loading"
          :disabled="loading"
        >
          更新
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import ShopAPI from '@/services/shop'
import NewsAPI from '@/services/news'

import { VueEditor } from 'vue2-editor'

export default {
  components: {
    VueEditor
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: 'ホーム',
          disabled: false,
          to: { name: 'Dashboard' }
        },
        {
          text: 'お知らせ',
          disabled: false,
          to: { name: 'News' },
          exact: true
        },
        {
          text: '新規作成',
          disabled: false,
          to: { name: 'CreateNews' },
          exact: true
        }
      ],
      alert: false,
      status: null,
      statusMessage: null,
      loading: false,
      errorMessages: '',
      rules: {
        title: [v => !!v || 'タイトル は必須項目です'],
        image: [
          v => !v || v.size < 2000000 || '2MB以下の画像を登録してください。'
        ]
      },
      news: {
        title: null,
        content: null,
        branch_id: null,
        image: null
      },
      customToolbar: [
        [{ header: [1, 2, 3, false] }],
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }]
      ],
      shops: [],
      url: null,
      brand_id: null
    }
  },

  methods: {
    submit() {
      this.formHasErrors = false
      Object.keys(this.rules).forEach(f => {
        let success = this.$refs[f].validate(true)
        if (!success) {
          this.formHasErrors = true
        }
      })
      if (!this.formHasErrors) {
        this.createNews()
      }
    },

    getshops() {
      ShopAPI.getShops({
        shop_id: this.brand_id,
        isLessData: true
      }).then(res => {
        this.shops.push({id:'all', name: '全ての店舗'})
        this.shops.push(...res.data.data)
      })
    },

    changeBranch() {
      if (this.news.branch_id.includes('all')) {
        this.news.branch_id = this.shops.map((shop) => {
          return shop.id;
        })
      }
    },

    createNews() {
      this.loading = true
      NewsAPI.createNews(this.formData)
        .then(res => {
          this.status = res.data.status
          this.statusMessage = res.data.message

          this.loading = false
          let payload = {
            status: res.data.status,
            message: res.data.message
          }

          this.$store.commit('setAlert', payload)

          this.$router.push({
            name: 'News',
            params: { brand: this.$route.params.brand }
          })
        })
        .catch(e => {
          this.loading = false
          this.errorMessages = e.data.errors || 'unexpected error'
        })
    },

    previewImage() {
      if (this.news.image) {
        this.url = URL.createObjectURL(this.news.image)
      }
    }
  },

  beforeMount() {
    this.brand_id = this.$store.getters.brands.find(
      x => x.name === this.$route.params.brand
    ).id
    this.getshops()
  },

  computed: {
    form() {
      return {
        title: this.news.title,
        content: this.news.content,
        branch_id: this.news.branch_id,
        image: this.news.image
      }
    },

    formData() {
      var formData = new FormData()
      Object.keys(this.form).forEach(key => {
        if (!(this.form[key] == null || this.form[key] === '')) {
          formData.append(key, this.form[key])
        }
      })
      formData.append('shop_id', this.brand_id)
      return formData
    }
  }
}
</script>
