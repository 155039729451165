<template>
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>

    <v-alert v-if="alert" :type="alert.status" dismissible text>
      {{ alert.message }}
    </v-alert>

    <v-card elevation="2" class="pa-5 mt-5">
      <v-card-title>
        管理者一覧
        <v-col class="text-right">
          <v-btn
            small
            color="secondary white--text"
            :to="{ name: 'CreateAdmin' }"
          >
            管理者登録
          </v-btn>
        </v-col>
      </v-card-title>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="users"
          :options.sync="options"
          :server-items-length="totalUsers"
          :loading="loading"
        >
          <template v-slot:[`item.index`]="{ item }">
            {{ options.itemsPerPage * (options.page - 1) + (users.indexOf(item) + 1) }}
          </template>
          <template v-slot:[`item.user_type`]="{ item }">
            {{ item.user_type == 'admin' ? 'Super Admin' : (item.user_type == 'subadmin' ? 'Admin' : '-') }}
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              x-small
              icon
              class="mr-2"
              :to="{ name: 'EditAdmin', params: { id: item.id } }"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AdminAPI from '@/services/admin'
export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: 'ホーム',
          disabled: false,
          to: { name: 'Dashboard' }
        },
        {
          text: '管理者',
          disabled: false,
          to: { name: 'Admin' },
          exact: true
        }
      ],
      totalUsers: 0,
      users: [],
      loading: true,
      options: {},
      headers: [
        {
          text: 'ID',
          align: 'start',
          value: 'index'
        },
        { text: '氏名', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: '権限', value: 'user_type' },
        { text: 'ステータス', value: 'status' },
        // { text: 'Last login at', value: 'last_login' },
        { text: 'アクション', value: 'actions', sortable: false }
      ]
    }
  },

  watch: {
    options: {
      handler() {
        this.getUsersFromApi()
      },
      deep: true
    }
  },

  computed: {
    // ...mapState(['alert'])
    ...mapState({
      alert: state => state.alert,
      user: state => state.auth.user
    }),
    reqParams() {
      return {
        itemsPerPage: this.options.itemsPerPage,
        page: this.options.page,
        sortBy: this.options.sortBy[0] || null,
        sortDesc: this.options.sortDesc[0],
      }
    }
  },

  mounted() {
    if (this.alert != null) {
      setTimeout(() => {
        this.$store.commit('resetAlert')
      }, 5000)
    }
  },

  methods: {
    getUsersFromApi() {
      this.loading = true
      AdminAPI.getUsers(this.reqParams).then(res => {
        this.users = res.data.data.data
        this.totalUsers = res.data.data.total
        this.loading = false
      })
    }
  }
}
</script>
