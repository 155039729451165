<template>
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>

    <v-alert v-if="alert" :type="alert.status" dismissible text>
      {{ alert.message }}
    </v-alert>

    <v-card elevation="2" class="pa-5 mt-5">
      <v-card-title>
        Brand lists
        <v-col class="text-right">
          <v-btn
            small
            color="secondary white--text"
            :to="{ name: 'CreateBrand' }"
          >
            Create Brand
          </v-btn>
        </v-col>
      </v-card-title>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="brands"
          :options.sync="options"
          :server-items-length="totalUsers"
          :loading="loading"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              x-small
              icon
              class="mr-2"
              :to="{ name: 'EditBrand', params: { id: item.id } }"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-icon small @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import BrandAPI from '@/services/brand'
import { mapState } from 'vuex'
export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: 'ホーム',
          disabled: false,
          to: { name: 'Dashboard' }
        },
        {
          text: 'Shop-info',
          disabled: true
        },
        {
          text: 'Brand',
          disabled: false,
          to: { name: 'Brand' },
          exact: true
        },
        {
          text: 'List',
          disabled: false,
          to: { name: 'Brand' },
          exact: true
        }
      ],
      totalUsers: 0,
      brands: [],
      loading: true,
      options: {},
      headers: [
        {
          text: 'ID',
          align: 'start',
          value: 'id'
        },

        { text: 'Name', value: 'name' },
        { text: 'Status', value: 'status' },
        { text: 'アクション', value: 'actions', sortable: false }
      ]
    }
  },
  watch: {
    options: {
      handler() {
        this.getBrandFromApi()
      },
      deep: true
    }
  },

  methods: {
    getBrandFromApi() {
      this.loading = true
      BrandAPI.getBrands().then(res => {
        this.brands = res.data.data.data
        this.totalUsers = res.data.data.total
        this.loading = false
      })
    },
    deleteItem(item) {
      this.$swal
        .fire({
          title: '本当に削除してもよろしいですか？',
          text: '一度削除すると元に戻せません。',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '削除',
          cancelButtonText: '戻る'
        })
        .then(result => {
          if (result.isConfirmed) {
            BrandAPI.deleteBrand(item.id)
              .then(response => {
                if (response.data.status == 'success') {
                  this.$swal.fire(
                    'Deleted!',
                    'User has been deleted.',
                    'success'
                  )
                  this.brands.splice(
                    this.brands.findIndex(e => e.id == item.id),
                    1
                  )
                }
              })
              .catch(e => {
                this.$swal.fire(
                  'Server error',
                  'Oops! something wrong, please try again later',
                  'error'
                )
              })
          }
        })
    }
  },

  computed: {
    ...mapState(['alert'])
  },

  mounted() {
    if (this.alert != null) {
      setTimeout(() => {
        this.$store.commit('resetAlert')
      }, 5000)
    }
  }
}
</script>
