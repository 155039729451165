<template>
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>

    <v-card elevation="2" class="pa-5 mt-5">
      <v-card-title>
        管理者登録
      </v-card-title>

      <v-card-text>
        <v-alert v-if="alert" :type="status" :value="alert" dismissible text>
          {{ statusMessage }}
        </v-alert>

        <v-row>
          <v-col cols="12" md="6"
            ><v-text-field
              v-model="name"
              ref="name"
              :rules="rules.name"
              label="氏名"
              prepend-inner-icon="mdi-account"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6"
            ><v-text-field
              v-model="email"
              ref="email"
              :rules="rules.email"
              :error-messages="emailExistError"
              label="E-mail"
              prepend-inner-icon="mdi-email"
              required
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6"
            ><v-text-field
              v-model="password"
              ref="password"
              :rules="rules.password"
              label="新パスワード"
              prepend-inner-icon="mdi-lock"
              :type="'password'"
              autocomplete="new-password"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="password_confirmation"
              ref="password_confirmation"
              :rules="rules.password_confirmation"
              :error-messages="errorMessages"
              label="パスワード再入力"
              prepend-inner-icon="mdi-lock-open"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
              @change="errorMessages = null"
              hide-details="auto"
            ></v-text-field
          ></v-col>
        </v-row>

        <v-row v-if="user && user.user_type == 'admin'">
          <v-col cols="12" md="6">
            <span>管理者権限</span>
            <v-radio-group
              v-model="role"
              ref="role"
              @change="toggleShop"
              row
              mandatory
            >
              <v-radio label="Admin" value="subadmin"></v-radio>
              <v-radio label="Super Admin" value="admin"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" md="6" v-if="visible">
            <v-select
              ref="shop_id"
              v-model="shop_id"
              :items="brands"
              item-value="id"
              item-text="name"
              label="所属店舗"
            />
          </v-col>
        </v-row>

        <v-switch
          v-model="active"
          ref="active"
          :label="active ? '有効' : '無効'"
        ></v-switch>
      </v-card-text>

      <v-divider class="mt-12"></v-divider>
      <v-card-actions class="card-actions">
        <v-btn small :to="{ name: 'Admin' }">
          戻る
        </v-btn>
        <v-btn
          color="secondary white--text"
          small
          @click="submit"
          :loading="loading"
          :disabled="loading"
        >
          更新
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AdminAPI from '@/services/admin'
export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: 'ホーム',
          disabled: false,
          to: { name: 'Dashboard' }
        },
        {
          text: 'Users',
          disabled: true,
          to: { name: 'Dashboard' }
        },
        {
          text: '管理者',
          disabled: false,
          to: { name: 'Admin' },
          exact: true
        },
        {
          text: '新規作成',
          disabled: false,
          to: { name: 'CreateAdmin' },
          exact: true
        }
      ],
      alert: false,
      status: null,
      statusMessage: null,
      name: null,
      email: null,
      password: null,
      password_confirmation: null,
      role: null,
      shop_id: null,
      active: true,
      rules: {
        name: [v => !!v || 'Name は必須項目です'],
        email: [
          v => !!v || 'E-mail は必須項目です',
          v =>
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
            '正しいメールアドレスの形式で入力してください。'
        ],
        password: [
          v => !!v || '「パスワード」の入力は必要です。',
          v => (v && v.length >= 6) || 'パスワードの長さは6文字以上必要です。'
        ],
        password_confirmation: [
          v => !!v || 'パスワード再入力」の入力は必要です。',
          v => (v && v.length >= 6) || 'パスワードの長さは6文字以上必要です。',
          v =>
            v === this.password ||
            '新パスワードを再入力パスワードが一致しません。'
        ],
        role: [v => !!v || 'Role は必須項目です']
      },
      showPassword: false,
      errorMessages: null,
      loading: false,
      emailExistError: null,
      visible: null
    }
  },

  methods: {
    toggleShop() {
      if (this.role == 'subadmin') this.visible = true
      else this.visible = false
    },
    resetForm() {
      this.errorMessages = []
      Object.keys(this.form).forEach(f => {
        this.$refs[f].reset()
      })
    },
    submit() {
      this.formHasErrors = false
      Object.keys(this.form).forEach(f => {
        if (!this.form[f]) this.formHasErrors = true
        this.$refs[f].validate(true)
      })
      if (!this.formHasErrors) {
        this.createAdmin()
      }
    },
    createAdmin() {
      this.loading = true
      AdminAPI.createUser(this.formData)
        .then(res => {
          this.status = res.data.status
          this.statusMessage = res.data.message
          this.resetForm()
          this.loading = false
          this.alert = true
          setTimeout(() => {
            this.alert = false
          }, 5000)
        })
        .catch(e => {
          this.loading = false
          console.log(e)
        })
    }
  },

  computed: {
    ...mapState({
      brands: state => state.auth.brands,
      user: state => state.auth.user
    }),
    form() {
      return {
        name: this.name,
        email: this.email,
        password: this.password,
        password_confirmation: this.password_confirmation
        // role: this.role
      }
    },
    formData() {
      // if user is shop admin, then pass their corresponding shop id, and role
      let shop_id = this.shop_id
      let role = this.role
      if (this.user && this.user.user_type == 'subadmin') {
        shop_id = this.brands[0].id
        role = 'subadmin'
      }
      var formData = new FormData()
      formData.append('name', this.name)
      formData.append('email', this.email)
      formData.append('password', this.password)
      formData.append('password_confirmation', this.password_confirmation)
      formData.append('user_type', role)
      formData.append('shop_id', shop_id)
      formData.append('status', this.active ? 1 : 0)
      return formData
    }
  },

  watch: {
    email(val) {
      var valid_email_format = this.$refs['email'].validate(true)
      if (valid_email_format) {
        AdminAPI.checkEmail(val)
          .then(res => {
            if (res.data.status == 'error') {
              this.emailExistError = res.data.message
            } else {
              this.emailExistError = null
            }
          })
          .catch(e => {
            console.log(e)
          })
      }
    }
  }
}
</script>
