import axios from 'axios'
export default {
  getCategories(params = null) {
    return axios.get('categories', { params }).then(res => {
      if (params.parent_id) {
        return res
      }
      let objectData = res.data.data.data ? res.data.data.data : res.data.data
      Object.entries(objectData).forEach(category => {
        category[1].status = category[1].status == 1 ? '表示中' : '非表示'
      })
      return res
    })
  },
  getCategory(id) {
    return axios.get('categories/' + id).then(res => {
      let category = res.data.data
      category.type = category.category_type
      return res
    })
  },
  getCategoriesTree(id, params = null) {
    return axios.get('categories/' + id + '/tree')
  },
  createCategory(params) {
    return axios.post('categories', params)
  },
  updateCategory(id, params) {
    return axios.post('categories/' + id, params)
  },
  deleteCategory(id) {
    return axios.delete('categories/' + id)
  },

  getChildren(cat_id) {
    return axios.get('categories/' + cat_id + '/children')
  }
}
