<template>
  <div>
    <v-card elevation="2" class="pa-5 mt-5">
      <v-card-title>
        パスワード変更
      </v-card-title>

      <v-card-text>
        <v-alert v-if="alert" :type="status" :value="alert" dismissible text>
          {{ statusMessage }}
        </v-alert>
        <v-col md="8">
          <v-text-field
            v-model="old_password"
            ref="old_password"
            :rules="rules.old_password"
            label="現在のパスワード"
            prepend-inner-icon="mdi-lock"
            :type="'password'"
            :error-messages="errorMessages.old_password"
          ></v-text-field>

          <v-text-field
            v-model="password"
            ref="password"
            :rules="rules.password"
            label="新しいパスワード"
            prepend-inner-icon="mdi-lock"
            :type="'password'"
            autocomplete="new-password"
            :error-messages="errorMessages.password"
          ></v-text-field>

          <v-text-field
            v-model="password_confirmation"
            ref="password_confirmation"
            :rules="rules.password_confirmation"
            :error-messages="errorMessages.password_confirmation"
            label="パスワード確認"
            prepend-inner-icon="mdi-lock-open"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            @change="errorMessages = ''"
            hide-details="auto"
          ></v-text-field>
        </v-col>
      </v-card-text>
      <v-divider class="mt-12"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="submit"
          :loading="loading"
          :disabled="loading"
        >
          更新する
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import PasswordAPI from '@/services/auth'

export default {
  data() {
    return {
      alert: false,
      status: null,
      statusMessage: null,
      showPassword: false,
      old_password: null,
      password: null,
      password_confirmation: null,
      rules: {
        old_password: [v => !!v || '現在のパスワードが必須です。'],
        password: [
          v => !!v || '新しいパスワードが必須です。',
          v => (v && v.length >= 6) || 'パスワードの長さは6文字以上必要です。'
        ],
        password_confirmation: [
          v => !!v || 'パスワード確認が必須です。',
          v => (v && v.length >= 6) || 'パスワードの長さは6文字以上必要です。',
          v =>
            v === this.password ||
            '新パスワードを再入力パスワードが一致しません。'
        ]
      },
      errorMessages: '',
      loading: false
    }
  },
  methods: {
    resetForm() {
      this.errorMessages = []
      Object.keys(this.form).forEach(f => {
        this.$refs[f].reset()
      })
    },
    submit() {
      this.formHasErrors = false
      Object.keys(this.form).forEach(f => {
        if (this.form[f] === null) this.formHasErrors = true
        this.$refs[f].validate(true)
      })
      if (!this.formHasErrors) {
        this.updatePassword()
      }
    },
    updatePassword() {
      PasswordAPI.changePassword(this.form)
        .then(res => {
          this.status = res.data.status
          this.statusMessage = '更新が完了しました'
          this.loading = false
          this.alert = true
          setTimeout(() => {
            this.alert = false
          }, 5000)
          this.resetForm()
        })
        .catch(e => {
          this.loading = false
          this.errorMessages = e.data.errors
        })
    }
  },
  computed: {
    form() {
      return {
        old_password: this.old_password,
        password: this.password,
        password_confirmation: this.password_confirmation
      }
    }
  }
}
</script>
