import axios from 'axios'

export default {
  getUsers(params = null) {
    return axios.get('users', { params }).then(res => {
      Object.entries(res.data.data.data).forEach(user => {
        user[1].status = user[1].status == 1 ? '有効' : '無効'
      })
      return res
    })
  },
  getUser(id) {
    return axios.get('users/' + id)
  },
  createUser(params) {
    // for (var pair of params.entries()) {
    //   console.log(pair[0] + ', ' + pair[1])
    // }
    return axios.post('users', params)
  },
  checkEmail(email) {
    return axios.get('email/check/' + email)
  },
  updateUSer(id, params) {
    return axios.put('users/' + id, params)
  },
  deleteUser(id) {
    return axios.delete('users/' + id)
  }
}
