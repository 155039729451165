<template>
  <v-app>
    <main>
      <v-container fill-height>
        <v-row justify="center" align="center">
          <v-col cols="12" md="8" lg="6" xl="4">
            <v-card elevation="12" class="py-8">
              <v-card-text class="text-center">
                <h2 class="text-h4 black--text">
                  パスワード再設定
                </h2>
                <v-form v-model="isValid">
                  <div class="mt-6">
                    <v-row justify="center">
                      <v-col cols="12" md="10">
                        <v-alert
                          v-if="alert"
                          border="left"
                          outlined
                          :type="status"
                          :value="alert"
                        >
                          {{ message }}
                        </v-alert>
                        <v-text-field
                          v-model="new_password"
                          :rules="rules.new_password"
                          label="新パスワード"
                          outlined
                          prepend-inner-icon="mdi-lock-open"
                          :type="'password'"
                          hide-details="auto"
                          class="mt-6"
                        ></v-text-field>

                        <v-text-field
                          v-model="confirm_password"
                          :rules="rules.confirm_password"
                          :error-messages="errorMessages"
                          label="パスワード再入力"
                          outlined
                          prepend-inner-icon="mdi-lock-open"
                          :type="showPassword ? 'text' : 'password'"
                          :append-icon="
                            showPassword ? 'mdi-eye' : 'mdi-eye-off'
                          "
                          @click:append="showPassword = !showPassword"
                          @change="errorMessages = null"
                          hide-details="auto"
                          class="mt-6"
                        ></v-text-field>

                        <div class="mt-6">
                          <v-btn
                            block
                            class="text-capitalize"
                            large
                            color="primary"
                            :disabled="!isValid"
                            @click="reset"
                            >パスワード再設定</v-btn
                          >
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </main>
  </v-app>
</template>

<script>
import ResetAPI from '@/services/auth'
export default {
  name: 'ResetPassword',
  data() {
    return {
      showPassword: false,
      new_password: null,
      confirm_password: null,
      isValid: true,
      rules: {
        new_password: [
          v => !!v || '「パスワード」の入力は必要です。',
          v => (v && v.length >= 6) || 'パスワードの長さは6文字以上必要です。'
        ],
        confirm_password: [
          v => !!v || 'パスワード再入力」の入力は必要です。',
          v => (v && v.length >= 6) || 'パスワードの長さは6文字以上必要です。',
          v =>
            v === this.new_password ||
            '新パスワードを再入力パスワードが一致しません。'
        ]
      },
      loading: false,
      alert: false,
      status: null,
      message: null,
      errorMessages: null
    }
  },

  computed: {
    form() {
      return {
        email: this.$route.query.email,
        token: this.$route.params.token,
        password: this.new_password,
        password_confirmation: this.confirm_password
      }
    }
  },

  methods: {
    reset() {
      this.loading = true
      if (this.new_password != this.confirm_password) {
        this.errorMessages = '新パスワードを再入力パスワードが一致しません。'
        return 0
      }
      ResetAPI.reset(this.form)
        .then(res => {
          this.loading = false
          this.resetForm
          if (res.data.status == 'error') {
            this.status = res.data.status
            this.alert = true
            this.message = res.data.message
          } else {
            let payload = {
              status: res.data.status,
              message: res.data.message
            }
            this.$store.commit('setAlert', payload)
            this.$router.push({ name: 'Login' })
          }
        })
        .catch(e => {
          this.loading = false
          console.log(e)
        })
    }
  }
}
</script>
<style scoped>
main {
  background-color: rgba(244, 245, 247, 1);
  height: 100vh;
}
</style>
