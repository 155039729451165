<template>
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-card elevation="2" class="pa-5 mt-5">
      <v-card-title>
        管理者を編集する
      </v-card-title>

      <div v-if="user">
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6"
              ><v-text-field
                v-model="user.name"
                :value="user.name"
                ref="name"
                :rules="rules.name"
                label="氏名"
                prepend-inner-icon="mdi-account"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6"
              ><v-text-field
                v-model="user.email"
                :value="user.email"
                ref="email"
                :rules="rules.email"
                :error-messages="emailExistError"
                label="E-mail"
                prepend-inner-icon="mdi-email"
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row v-if="auth_user && auth_user.user_type == 'admin'">
            <v-col cols="12" md="6">
              <span>管理者権限</span>
              <v-radio-group
                v-model="user.role"
                ref="role"
                @change="toggleShop"
                :value="user.role"
                row
                mandatory
              >
                <v-radio label="Admin" value="subadmin"></v-radio>
                <v-radio label="Super Admin" value="admin"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" v-if="visible">
              <v-select
                ref="shop_id"
                v-model="user.shop_id"
                :items="brands"
                item-value="id"
                item-text="name"
                label="所属店舗"
              />
            </v-col>
          </v-row>

          <v-switch
            v-model="user.status"
            :value="user.status"
            ref="active"
            label="ステータス"
          ></v-switch>
        </v-card-text>

        <v-divider class="mt-12"></v-divider>
        <v-card-actions class="card-actions">
          <v-btn small :to="{ name: 'Admin' }">
            戻る
          </v-btn>
          <v-btn
            color="secondary white--text"
            small
            @click="submit"
            :loading="loading"
            :disabled="loading"
          >
            更新
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AdminAPI from '@/services/admin'
export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: 'ホーム',
          disabled: false,
          to: { name: 'Dashboard' }
        },
        {
          text: 'Users',
          disabled: true,
          to: { name: 'Dashboard' }
        },
        {
          text: '管理者',
          disabled: false,
          to: { name: 'Admin' },
          exact: true
        },
        {
          text: '編集',
          disabled: false,
          to: { name: 'EditAdmin' },
          exact: true
        }
      ],
      rules: {
        name: [v => !!v || 'Name は必須項目です'],
        email: [
          v => !!v || 'E-mail は必須項目です',
          v =>
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
            '正しいメールアドレスの形式で入力してください。'
        ],
        role: [v => !!v || 'Role は必須項目です']
      },
      loading: false,
      emailExistError: null,
      user: null,
      visible: null
    }
  },

  computed: {
    ...mapState({
      brands: state => state.auth.brands,
      auth_user: state => state.auth.user
    }),
    form() {
      return {
        name: this.user.name,
        email: this.user.email
        // role: this.user.role
      }
    },
    formData() {
      return {
        name: this.user.name,
        email: this.user.email,
        user_type: this.user.role,
        status: this.user.status ? 1 : 0,
        shop_id: this.user.shop_id
      }
    }
  },

  watch: {
    email(val) {
      var valid_email_format = this.$refs['email'].validate(true)
      if (valid_email_format) {
        AdminAPI.checkEmail(val)
          .then(res => {
            if (res.data.status == 'error') {
              this.emailExistError = res.data.message
            } else {
              this.emailExistError = null
            }
          })
          .catch(e => {
            console.log(e)
          })
      }
    }
  },

  mounted() {
    this.getAdminDetails(this.$route.params.id)
  },

  methods: {
    getAdminDetails(id) {
      this.loading = true
      AdminAPI.getUser(id)
        .then(response => {
          this.loading = false
          this.user = response.data.data
          this.user.status = this.user.status == 1 ? true : false
          this.user.role = this.user.user_type
          if (this.user.role == 'subadmin') this.visible = true
        })
        .catch(e => {
          this.loading = false
          console.log(e)
        })
    },
    submit() {
      let formHasErrors = false
      Object.keys(this.form).forEach(f => {
        if (!this.form[f]) formHasErrors = true
        this.$refs[f].validate(true)
      })
      if (!formHasErrors) {
        this.updateAdmin()
      }
    },

    updateAdmin() {
      this.loading = true
      AdminAPI.updateUSer(this.user.id, this.formData)
        .then(response => {
          this.loading = false
          let payload = {
            status: response.data.status,
            message: '更新が完了しました'
          }
          this.$store.commit('setAlert', payload)
          this.$router.push({ name: 'Admin' })
        })
        .catch(e => {
          this.loading = false
          console.log(e)
        })
    },

    deleteItem(item) {
      this.$swal
        .fire({
          title: '本当に削除してもよろしいですか？',
          text: '一度削除すると元に戻せません。',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '削除',
          cancelButtonText: '戻る'
        })
        .then(result => {
          if (result.isConfirmed) {
            AdminAPI.deleteUser(item.id)
              .then(response => {
                this.loading = false
                let payload = {
                  status: response.data.status,
                  message: '削除完了'
                }
                this.$store.commit('setAlert', payload)
                this.$router.push({ name: 'Admin' })
              })
              .catch(e => {
                this.$swal.fire(
                  'Server error',
                  'Oops! something wrong, please try again later',
                  'error'
                )
              })
          }
        })
    },
    toggleShop() {
      if (this.user.role == 'subadmin') this.visible = true
      else this.visible = false
    }
  }
}
</script>
