<template>
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>

    <v-card elevation="2" class="pa-5 mt-5">
      <v-card-title>
        お知らせ編集
      </v-card-title>
      <div v-if="news">
        <v-card-text>
          <v-text-field
            v-model="news.title"
            ref="title"
            :error-messages="errorMessages.title"
            :rules="rules.title"
            label="タイトル"
          ></v-text-field>

          <label>内容</label>
          <vue-editor
            v-model="news.content"
            ref="content"
            class="mb-4"
            :editorToolbar="customToolbar"
            :error-messages="errorMessages.content"
          ></vue-editor>

          <v-autocomplete
            ref="branch_id"
            v-model="news.branch_id"
            :items="shops"
            item-text="name"
            item-value="id"
            @change="changeBranch()"
            :menu-props="{ maxHeight: '400' }"
            label="対象店舗"
            multiple
          ></v-autocomplete>

          <v-file-input
            @change="previewImage"
            v-model="news.image"
            ref="image"
            :rules="rules.image"
            :error-messages="errorMessages.image"
            show-size
            label="画像"
          >
          </v-file-input>
          <v-img :src="url" max-height="150" max-width="250"></v-img>
          <p class="recommended-image">推奨する画像のサイズ : 縦 800 横 500</p>

          <v-divider class="mt-12"></v-divider>
          <v-card-actions class="card-actions">
            <v-btn small :to="{ name: 'News' }">
              戻る
            </v-btn>

            <v-btn small color="red" @click="deleteItem(news)">
              削除
            </v-btn>

            <v-btn
              color="secondary white--text"
              small
              @click="submit"
              :loading="loading"
              :disabled="loading"
            >
              更新
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </div>
    </v-card>
  </div>
</template>

<script>
import ShopAPI from '@/services/shop'
import NewsAPI from '@/services/news'

import { VueEditor } from 'vue2-editor'

export default {
  components: {
    VueEditor
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: 'ホーム',
          disabled: false,
          to: { name: 'Dashboard' }
        },
        {
          text: 'お知らせ',
          disabled: false,
          to: { name: 'News' },
          exact: true
        },
        {
          text: '編集',
          disabled: false,
          to: { name: 'EditNews' },
          exact: true
        }
      ],
      loading: false,
      errorMessages: '',
      rules: {
        title: [v => !!v || 'タイトル は必須項目です'],
        image: [
          v => !v || v.size < 2000000 || '2MB以下の画像を登録してください。'
        ]
      },
      news: {
        title: null,
        content: null,
        branch_id: null,
        image: null
      },
      customToolbar: [
        [{ header: [1, 2, 3, false] }],
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }]
      ],
      shops: [],
      url: null,
      brand_id: null
    }
  },

  methods: {
    submit() {
      this.formHasErrors = false
      Object.keys(this.rules).forEach(f => {
        let success = this.$refs[f].validate(true)
        if (!success) {
          this.formHasErrors = true
        }
      })
      if (!this.formHasErrors) {
        this.updateNews()
      }
    },

    getshops() {
      ShopAPI.getShops({
        shop_id: this.brand_id,
        isLessData: true
      }).then(res => {
        this.shops.push({id:'all', name: '全ての店舗'})
        this.shops.push(...res.data.data)
      })
    },

    changeBranch() {
      if (this.news.branch_id.includes('all')) {
        this.news.branch_id = this.shops.map((shop) => {
          return shop.id;
        })
      }
    },

    getNewsDetails(id) {
      this.loading = true
      NewsAPI.getNews(id)
        .then(response => {
          this.loading = false
          this.news = response.data.data
          if (this.news.image) {
            this.url =
              process.env.VUE_APP_ASSET_URL + 'images/news/' + this.news.image
          }
          this.news.image = null
          this.news.status = this.news.status == 1 ? true : false
          this.news.branch_id = this.news.branches.map(function(branch) {
            return branch.id
          })
        })
        .catch(e => {
          this.loading = false
          console.log(e)
        })
    },

    updateNews() {
      this.loading = true
      NewsAPI.updateNews(this.news.id, this.formData)
        .then(response => {
          this.loading = false
          let payload = {
            status: response.data.status,
            message: '更新が完了しました'
          }
          this.$store.commit('setAlert', payload)
          this.$router.push({
            name: 'News',
            params: { id: this.$route.params.brand }
          })
        })
        .catch(e => {
          this.loading = false
          console.log(e)
        })
    },

    previewImage() {
      if (this.news.image) {
        this.url = URL.createObjectURL(this.news.image)
      }
    },

    deleteItem(item) {
      this.$swal
        .fire({
          title: '本当に削除してもよろしいですか？',
          text: '一度削除すると元に戻せません。',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '削除',
          cancelButtonText: '戻る'
        })
        .then(result => {
          if (result.isConfirmed) {
            NewsAPI.deleteNews(item.id)
              .then(response => {
                this.loading = false
                let payload = {
                  status: response.data.status,
                  message: '削除完了'
                }
                this.$store.commit('setAlert', payload)
                this.$router.push({
                  name: 'News',
                  params: { id: this.$route.params.brand }
                })

                // if (response.data.status == 'success') {
                //   this.$swal.fire(
                //     'Deleted!',
                //     'News has been deleted.',
                //     'success'
                //   )
                //   this.news.splice(
                //     this.news.findIndex(e => e.id == item.id),
                //     1
                //   )
                // }
              })
              .catch(e => {
                this.$swal.fire(
                  'Server error',
                  'Oops! something wrong, please try again later',
                  'error'
                )
              })
          }
        })
    }
  },

  beforeMount() {
    this.brand_id = this.$store.getters.brands.find(
      x => x.name === this.$route.params.brand
    ).id
    this.getNewsDetails(this.$route.params.id)
    this.getshops()
  },

  computed: {
    form() {
      return {
        title: this.news.title,
        content: this.news.content,
        branch_id: this.news.branch_id,
        image: this.news.image
      }
    },

    formData() {
      var formData = new FormData()
      Object.keys(this.form).forEach(key => {
        if (!(this.form[key] == null || this.form[key] === '')) {
          formData.append(key, this.form[key])
        }
      })
      formData.append('shop_id', this.brand_id)
      formData.append('_method', 'put')
      return formData
    }
  }
}
</script>
