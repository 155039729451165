import axios from 'axios'
export default {
  createShop(params, brand_id) {
    return axios.post('shop/' + brand_id + '/branches', params)
  },
  getShops(params) {
    return axios
      .get('shop/' + params.shop_id + '/branches', { params })
      .then(res => {
        if (params.isLessData) {
          return res
        }
        Object.entries(res.data.data.data).forEach(shop => {
          shop[1].status = shop[1].status == 1 ? '表示中' : '非表示'
        })
        return res
      })
  },
  getShop(id) {
    return axios.get('branches/' + id)
  },
  updateShop(id, params) {
    return axios.post('branches/' + id, params)
  },
  deleteShop(id) {
    return axios.delete('branches/' + id)
  },
  deleteDelivery(id) {
    return axios.delete('branches/delivery/' + id)
  },
  deleteReservation(id) {
    return axios.delete('branches/reservation/' + id)
  }
}
