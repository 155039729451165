<template>
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-card elevation="2" class="pa-5 mt-5">
      <v-card-title>
        Edit Brand
      </v-card-title>

      <div v-if="brand">
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                ref="name"
                v-model="brand.name"
                :rules="rules.name"
                label="Brand name"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-textarea
                ref="phone"
                v-model="brand.phone"
                :rules="rules.phone"
                rows="1"
                auto-grow
                label="Phone"
                id="phone"
              ></v-textarea>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                ref="postcode"
                v-model="brand.postcode"
                :rules="rules.postcode"
                label="Postal code"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-textarea
                ref="prefecture"
                v-model="brand.prefecture"
                :rules="rules.prefecture"
                rows="1"
                auto-grow
                label="Prefecture"
                id="prefecture"
              ></v-textarea>
            </v-col>
            <v-col cols="12" sm="4">
              <v-textarea
                ref="city"
                v-model="brand.city"
                :rules="rules.city"
                rows="1"
                auto-grow
                label="City"
                id="city"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider class="mt-12"></v-divider>
        <v-card-actions>
          <v-btn text :to="{ name: 'Brand' }">
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="submit"
            :loading="loading"
            :disabled="loading"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </div>
</template>

<script>
import BrandAPI from '@/services/brand'
export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: 'ホーム',
          disabled: false,
          to: { name: 'Dashboard' }
        },
        {
          text: 'Shop-info',
          disabled: true
        },
        {
          text: 'Brand',
          disabled: false,
          to: { name: 'Brand' },
          exact: true
        },
        {
          text: '編集',
          disabled: false,
          to: { name: 'EditBrand' },
          exact: true
        }
      ],
      rules: {
        name: [v => !!v || 'Brand name は必須項目です'],
        phone: [v => !!v || 'Phone は必須項目です'],
        postcode: [v => !!v || 'Postal は必須項目です'],
        prefecture: [v => !!v || 'Prefecture は必須項目です'],
        city: [v => !!v || 'City は必須項目です']
      },
      loading: false,
      brand: null
    }
  },

  computed: {
    form() {
      return {
        name: this.brand.name,
        phone: this.brand.phone,
        postcode: this.brand.postcode,
        prefecture: this.brand.prefecture,
        city: this.brand.city
      }
    }
  },

  mounted() {
    this.getBrandDetails(this.$route.params.id)
  },

  methods: {
    getBrandDetails(id) {
      this.loading = true
      BrandAPI.getBrand(id)
        .then(response => {
          this.loading = false
          this.brand = response.data.data
        })
        .catch(e => {
          this.loading = false
          console.log(e)
        })
    },
    submit() {
      let formHasErrors = false
      Object.keys(this.form).forEach(f => {
        if (!this.form[f]) formHasErrors = true
        this.$refs[f].validate(true)
      })
      if (!formHasErrors) {
        this.updateBrand()
      }
    },

    updateBrand() {
      this.loading = true
      BrandAPI.updateBrand(this.brand.id, this.form)
        .then(response => {
          this.loading = false
          let payload = {
            status: response.data.status,
            message: '更新が完了しました'
          }
          this.$store.commit('setAlert', payload)
          this.$router.push({ name: 'Brand' })
        })
        .catch(e => {
          this.loading = false
          console.log(e)
        })
    }
  }
}
</script>
