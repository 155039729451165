<template>
  <v-app>
    <main>
      <v-container fill-height>
        <v-row justify="center" align="center">
          <v-col cols="12" md="8" lg="6" xl="4">
            <v-card elevation="12" class="py-8">
              <v-card-text class="text-center">
                <h2 class="text-h4 black--text">
                  パスワード再設定
                </h2>
                <v-form v-model="isValid">
                  <div class="mt-6">
                    <v-row justify="center">
                      <v-col cols="12" md="10">
                        <v-alert
                          v-if="alert"
                          border="left"
                          colored-border
                          :type="status"
                          elevation="2"
                        >
                          {{ message }}
                        </v-alert>
                        <p>登録したメールアドレスを入力してください。<br>
                        パスワード再発行用のメールを送信します。</p>
                        <v-text-field
                          v-model="email"
                          :rules="rules.email"
                          label="登録されたメールアドレス"
                          name="email"
                          outlined
                          prepend-inner-icon="mdi-account-outline"
                          hide-details="auto"
                        ></v-text-field>

                        <div class="d-flex align-center justify-end mt-4">
                          <!-- <v-checkbox label="Remember me"></v-checkbox> -->
                          <router-link :to="{ name: 'Login' }"
                            >ログイン画面に戻る</router-link
                          >
                        </div>

                        <div class="mt-6">
                          <v-btn
                            block
                            class="text-capitalize"
                            large
                            color="primary"
                            :disabled="!isValid"
                            @click="submit"
                            >パスワード再発行メールを送信</v-btn
                          >
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </main>
  </v-app>
</template>

<script>
import ResetAPI from '@/services/auth'

export default {
  name: 'ForgotPassword',
  data() {
    return {
      email: null,
      isValid: true,
      rules: {
        required: v => !!v || 'Required.',
        email: [
          v => !!v || '「登録されたメールアドレス」の入力は必要です。',
          v =>
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
            '正しいメールアドレスの形式で入力してください'
        ]
      },
      alert: false,
      message: null,
      status: null
    }
  },

  methods: {
    submit() {
      this.loading = true
      ResetAPI.sendResetLink(this.email)
        .then(res => {
          this.loading = false
          this.resetForm
          if (res.data.status) {
            this.alert = true
            this.status = res.data.status
            this.message = res.data.message
          }
        })
        .catch(e => {
          this.loading = false
          console.log(e)
        })
    },
    resetForm() {
      this.email = null
    }
  }
}
</script>

<style scoped>
main {
  background-color: rgba(244, 245, 247, 1);
  height: 100vh;
}
</style>
