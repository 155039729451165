import axios from 'axios'
var moment = require('moment')

export default {
  createRecruit(params, brand_id) {
    return axios.post('recruit/' + brand_id, params)
  },
  getRecruitList(params) {
    return axios.get('recruits', { params }).then(res => {
      Object.entries(res.data.data.data).forEach(recruit => {
        recruit[1].status = recruit[1].status == 1 ? '表示中' : '非表示'
        recruit[1].updated_at = moment(recruit[1].updated_at).format(
          'YYYY/MM/DD'
        )

        if (recruit[1].branches.length > 0)
          recruit[1].shop_name = recruit[1].branches
            .map(function(branch) {
              return branch.name
            })
            .join(', ')
        else recruit[1].shop_name = '全て'
      })
      return res
    })
  },
  getRecruit(id) {
    return axios.get('recruits/' + id)
  },
  updateRecruit(id, params) {
    return axios.post('recruits/' + id, params)
  },
  deleteRecruit(id) {
    return axios.delete('recruits/' + id)
  }
}
