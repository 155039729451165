import axios from 'axios'
var moment = require('moment')

export default {
  createNews(params) {
    return axios.post('news', params)
  },
  getNewsList(params) {
    return axios.get('news', { params }).then(res => {
      Object.entries(res.data.data.data).forEach(news => {
        news[1].status = news[1].status == 1 ? '表示中' : '非表示'

        if (news[1].branches.length > 0)
          news[1].shop_name = news[1].branches
            .map(function(branch) {
              return branch.name
            })
            .join(', ')
        else news[1].shop_name = '全て'

        news[1].updated_at = moment(news[1].updated_at).format('YYYY/MM/DD')
      })

      return res
    })
  },
  getNews(id) {
    return axios.get('news/' + id)
  },
  updateNews(id, params) {
    return axios.post('news/' + id, params)
  },
  deleteNews(id) {
    return axios.post('news/' + id + '/delete')
  }
}
