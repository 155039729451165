import axios from 'axios'
var moment = require('moment')

export default {
  getMenus(params) {
    return axios.get('menus', { params }).then(res => {
      let tax = res.data.shop.tax_inclusive ? '税込' : '税別'
      let objectData = res.data.data.data ? res.data.data.data : res.data.data
      Object.entries(objectData).forEach(menu => {
        menu[1].status = menu[1].status == 'active' ? '表示中' : '非表示'

        // getting main image from the image array
        menu[1].image = null
        menu[1].images.forEach(image => {
          if (image.is_main) {
            if(menu[1].shop_id==1){
              menu[1].image =
                process.env.VUE_APP_ASSET_URL + 'images/menus/' + image.image
            }else{
              menu[1].image =
                process.env.VUE_APP_ASSET_URL + 'images/menus/' + image.image
            }
          }
        })

        // category
        menu[1].category_name = menu[1].category.name_jp

        // price
        if (menu[1].has_submenu) {
          menu[1].price = ''
          menu[1].prices.forEach(submenu => {
            menu[1].price +=
              '<span>' +
              submenu.description +
              ' ' +
              submenu.price +
              '円 (' +
              tax +
              ')</span>'
          })
        } else menu[1].price = menu[1].price + '円 (' + tax + ')'
      })
      return res
    })
  },
  getMenu(id) {
    return axios.get('menus/' + id).then(res => {
      let menu = res.data.data
      menu.number_of_dishes = menu.no_of_dishes
      if (menu.prices.length) {
        menu.submenu = menu.prices
      } else {
        menu.submenu = [{ description: null, price: null }]
      }
      menu.show_homepage = 1
      menu.has_detail_page = menu.has_detail_page ? true : false
      menu.status = menu.status == 'active' ? 1 : 0
      menu.created_at = moment(menu.created_at).format('YYYY/MM/DD')

      res.data.data = menu
      return res
    })
  },
  createMenu(params) {
    return axios.post('menus', params)
  },
  updateMenu(id, params) {
    return axios.post('menus/' + id, params)
  },
  deleteMenu(id) {
    return axios.delete('menus/' + id)
  }
}
