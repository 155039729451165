<template>
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>

    <v-card elevation="2" class="pa-5 mt-5">
      <v-card-title> メニュー追加 </v-card-title>

      <v-card-text>
        <v-alert v-if="alert" :type="status" :value="alert" dismissible text>
          {{ statusMessage }}
        </v-alert>

        <v-row>
          <v-col
            cols="12"
            :lg="brand_id == 1 ? 12 : 6"
            :md="brand_id == 1 ? 12 : 9"
            sm="12"
          >
            <treeselect
              v-model="menu.category_id"
              :multiple="false"
              placeholder="選択"
              noChildrenText="該当項目が見つかりません。"
              noOptionsText="該当オプションがありません。"
              noResultsText="結果が見つかりません"
              :options="options"
              @select="categoryChange"
            />

            <v-checkbox
              v-model="menu.has_detail_page"
              ref="has_detail_page"
              label="詳細画面あり"
              disabled="disabled"
            ></v-checkbox>

            <v-text-field
              v-model="menu.name_jp"
              ref="name_jp"
              :rules="rules.name_jp"
              :error-messages="errorMessages.name_jp"
              :label="brand_id == 1 ? '商品名' : '料理名／コース名'"
            ></v-text-field>

            <v-text-field
              v-model="menu.name_en"
              ref="name_en"
              :rules="rules.name_en"
              :error-messages="errorMessages.name_en"
              :label="brand_id == 1 ? 'サブ商品名' : '料理名／コース名（英語）'"
            ></v-text-field>

            <v-text-field
              v-model="menu.slug"
              ref="slug"
              :error-messages="errorMessages.slug"
              :rules="rules.slug"
              label="スラグ"
            ></v-text-field>

            <v-text-field
              v-if="menu.has_detail_page && brand_id == 2"
              v-model="menu.number_of_dishes"
              ref="number_of_dishes"
              :rules="rules.number_of_dishes"
              :error-messages="errorMessages.number_of_dishes"
              label="品数"
            ></v-text-field>

            <label v-if="menu.has_detail_page && brand_id != 1"
              >料理詳細／コース詳細</label
            >
            <vue-editor
              v-if="menu.has_detail_page && brand_id != 1"
              v-model="menu.description"
              ref="description"
              :editorToolbar="customToolbar"
              :error-messages="errorMessages.description"
            ></vue-editor>

            <!-- <v-textarea
              v-if="menu.has_detail_page"
              v-show="brand_id != 1"
              ref="comment"
              v-model="menu.comment"
              :rules="rules.comment"
              :error-messages="errorMessages.comment"
              rows="1"
              auto-grow
              counter
              maxlength="64"
              label="コメント"
            ></v-textarea> -->

            <v-checkbox
              v-if="brand_id == 2"
              v-model="menu.has_submenu"
              ref="has_submenu"
              label="サブメニューあり"
            ></v-checkbox>

            <v-text-field
              v-if="!menu.has_submenu"
              v-model="menu.price"
              ref="price"
              :error-messages="errorMessages.price"
              :rules="rules.price"
              label="価格"
            ></v-text-field>

            <div v-if="menu.has_submenu">
              <div v-for="(submenu, i) in menu.submenu" :key="i">
                <v-row>
                  <v-col md="6">
                    <v-text-field
                      label="説明文"
                      v-model="submenu.description"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3">
                    <v-text-field
                      label="価格"
                      v-model="submenu.price"
                    ></v-text-field
                  ></v-col>

                  <v-icon color="blue darken-2" @click="add"> mdi-plus </v-icon>
                  <v-icon color="red" @click="remove(i)" v-if="i != 0"
                    >mdi-minus</v-icon
                  >
                </v-row>
              </div>
            </div>

            <div v-if="brand_id == 1">
              <v-row>
                <v-col :md="main_photo.image_path ? '11' : '12'">
                  <v-file-input
                    label="Upload Main Image"
                    v-model="main_photo.icon"
                    @change="showimagephoto('main')"
                  ></v-file-input>
                  <v-img
                    :lazy-src="main_photo.image_path"
                    max-height="150"
                    max-width="150"
                    :src="main_photo.image_path"
                  ></v-img>
                </v-col>
                <v-col v-if="main_photo.image_path" md="1">
                  <v-icon
                    @click="removePhotos('main')"
                    class="mt-6"
                  >
                    mdi-trash-can-outline
                  </v-icon>
                </v-col>
              </v-row>
            </div>

            <div
              v-if="parseInt(brand_id) == 1 && menu.has_detail_page === true"
            >
              <div
                v-for="(photoarr, photoArrayKey) in photosarr"
                :key="photoArrayKey"
              >
                <v-row>
                  <v-col md="6">
                    <vue-editor
                      v-model="photoarr.description"
                      ref="description"
                      :editorToolbar="customToolbar"
                      :error-messages="errorMessages.description"
                    ></vue-editor>
                  </v-col>
                  <v-col md="3">
                    <v-file-input
                      label="upload image"
                      v-model="photoarr.icon"
                      @change="showimagephoto(photoArrayKey)"
                    ></v-file-input>
                    <v-img
                      :lazy-src="photoarr.image_path"
                      max-height="150"
                      max-width="150"
                      :src="photoarr.image_path"
                    ></v-img>
                  </v-col>
                  <v-col md="3">
                    <v-icon
                    @click="removePhotos(photoArrayKey)"
                    class="mt-6"
                    >
                    mdi-trash-can-outline
                    </v-icon>
                    <!-- <v-icon color="red" @click="removePhotos(photoArrayKey)">mdi-delete</v-icon> -->
                  </v-col>
                </v-row>
              </div>
              <v-col cols="12 text-right mt-n6">
                <v-btn x-small @click="addphotos"> Add More </v-btn>
              </v-col>
            </div>
            <v-text-field
              v-model="menu.appealing_text"
              ref="appealing_text"
              :rules="rules.appealing_text"
              :error-messages="errorMessages.appealing_text"
              label="アピールポイント"
            ></v-text-field>

            <!-- <v-textarea
              v-if="menu.has_detail_page"
              v-show="brand_id != 1"
              ref="booking_info"
              v-model="menu.booking_info"
              :rules="rules.booking_info"
              :error-messages="errorMessages.booking_info"
              rows="1"
              auto-grow
              label="予約情報"
              id="booking_info"
            ></v-textarea> -->

            <div v-if="menu.show_homepage && !menu.has_detail_page">
              <v-card class="py-3 px-6 image-upload" outlined tile :img="url">
                <label for="id-1-photo-file" class="photo-cm-blk id-card">
                  <div class="new-upload" v-if="!url">
                    <v-icon color="indigo"> mdi-image-plus </v-icon>
                    <p class="indigo--text">アップロード</p>
                    <p class="grey--text ext-info">PNG, JPG, GIF</p>
                  </div>
                </label>

                <v-file-input
                  @change="previewImage"
                  class="d-none"
                  v-model="menu.cover_image"
                  ref="cover_image"
                  label="Cover image"
                  id="id-1-photo-file"
                >
                </v-file-input>
              </v-card>
            </div>

            <v-text-field
              v-model="menu.display_order"
              ref="display_order"
              :rules="rules.display_order"
              :error-messages="errorMessages.display_order"
              label="表示優先"
            ></v-text-field>

            <v-text-field
              v-if="menu.has_detail_page && brand_id == 2"
              v-model="menu.display_order_recommended"
              ref="display_order_recommended"
              :rules="rules.display_order_recommended"
              :error-messages="errorMessages.display_order_recommended"
              label="レコメンドに優先表示"
              placeholder="優先順位を数字で設定してください 。数字が小さい順に優先表示されます。"
            ></v-text-field>

            <v-switch
              v-model="menu.status"
              ref="status"
              label="表示/非表示"
            ></v-switch>

            <div v-if="menu.has_detail_page">
              <v-divider class="mb-12"></v-divider>
              <span class="text-h6 black--text">SEO</span>
              <v-textarea
                ref="meta_description"
                v-model="menu.meta_description"
                :rules="rules.meta_description"
                :error-messages="errorMessages.meta_description"
                rows="1"
                auto-grow
                label="メタディスクリプション"
              ></v-textarea>
            </div>
          </v-col>
          <!-- <v-col> </v-col> -->
          <v-col v-if="menu.has_detail_page && brand_id != 1">
            <div class="image-section">
              <vue-upload-multiple-image
                @upload-success="uploadImageSuccess"
                @before-remove="beforeRemove"
                @edit-image="editImage"
                :data-images="images"
                idUpload="myIdUpload"
                editUpload="myIdEdit"
                dragText="画像を選択してください"
                browseText=""
                primaryText="デフォルト"
                popupText="選択した画像がメインになります。"
                markIsPrimaryText="メイン画像に設定する"
                :maxImage="maxImages"
              ></vue-upload-multiple-image>
              <p class="recommended-image">推奨する画像のサイズ : 縦 300 横 300</p>
            </div>
          </v-col>
        </v-row>

        <v-divider class="mt-12"></v-divider>
        <v-card-actions class="card-actions">
          <v-btn small @click="savePostToDraft" color="secondary">
            下書きとして保存
          </v-btn>
          <!-- <v-btn @click="savePostAndPreview" color="indigo white--text">
            Preview
          </v-btn> -->
          <v-btn small :to="{ name: 'Menu' }"> 戻る </v-btn>

          <v-btn
            small
            color="secondary white--text"
            @click="submit"
            :loading="loading"
            :disabled="loading"
          >
            更新
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import MenuAPI from '@/services/menu'
import CategoryAPI from '@/services/category'
import { VueEditor } from 'vue2-editor'
import VueUploadMultipleImage from 'vue-upload-multiple-image'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  components: {
    VueEditor,
    VueUploadMultipleImage,
    Treeselect,
  },
  data() {
    return {
      options: [],
      imgpath: process.env.VUE_APP_ASSET_URL + 'images/menus/',
      breadcrumbs: [
        {
          text: 'ホーム',
          disabled: false,
          to: { name: 'Dashboard' },
        },
        {
          text: 'メニュー',
          disabled: false,
          to: { name: 'Menu' },
          exact: true,
        },
        {
          text: '新規作成',
          disabled: false,
          to: { name: 'CreateMenu' },
          exact: true,
        },
      ],
      main_photo: {
        icon: null,
        image_path: null
      },
      alert: false,
      status: null,
      statusMessage: null,
      menu: {
        category_id: null,
        name_jp: null,
        name_en: null,
        slug: null,
        number_of_dishes: null,
        description: null,
        comment: null,
        has_submenu: 0,
        submenu: [{ description: null, price: null }],
        price: null,
        meta_description: null,
        appealing_text: null,
        booking_info: null,
        show_homepage: null,
        cover_image: null,
        display_order: null,
        display_order_recommended: null,
        has_detail_page: true,
        status: true,
      },
      menu_status: null,
      rules: {
        // category_id: [v => !!v || 'Category は必須項目です'],
        // name_jp: [(v) => !!v || '料理名／コース名 は必須項目です'],
        // name_en: [(v) => !!v || '料理名／コース名（英語 は必須項目です'],
        slug: [(v) => !!v || 'スラグ は必須項目です'],
        // number_of_dishes: [v => !!v || 'Number of dishes は必須項目です'],
        price: [v => !!v || '価格 は必須項目です']
      },
      loading: false,
      errorMessages: '',
      categories: [],
      main_categories: [],
      customToolbar: [
        [{ header: [1, 2, 3, false] }],
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
      ],
      images: [],
      url: null,
      fileList: [],
      maxImages: 8,
      brand_id: null,
      photosarr: [],
      ismainradio: 0,
    }
  },
  watch: {},
  methods: {
    categoryChange: function (node, id) {
      if (node.category_type == 'course') {
        this.menu.has_detail_page = true
      } else {
        this.menu.has_detail_page = false
      }
    },
    ismainchange(photoarrk) {
      for (var i = 0; i < this.photosarr.length; i++) {
        this.photosarr[i].is_main = 0
      }
      this.photosarr[photoarrk].is_main = 1
    },
    addphotos() {
      this.photosarr.push({
        description: null,
        image_path: null,
        icon: null,
        is_main: this.photosarr.length == 0 ? 1 : 0,
      })
    },
    showimagephoto(_index) {
      if (_index == 'main') {
        var reader = new FileReader()
        reader.onload = (e) => {
          this.main_photo.image_path = e.target.result
        }
        reader.readAsDataURL(this.main_photo.icon)
      } else {
        var reader = new FileReader()
        reader.onload = (e) => {
          this.photosarr[_index].image_path = e.target.result
        }
        reader.readAsDataURL(this.photosarr[_index].icon)
      }
    },
    removePhotos(i) {
      if (i == 'main') {
        this.main_photo = {
          icon: null,
          image_path: null
        }
      } else {
        if (this.photosarr.length > 1) this.photosarr.splice(i, 1)
      }
    },
    updateUrl(v) {
      if (!v && this.url) {
        this.url = null
        this.menu.cover_image = null
      }
    },
    previewImage() {
      if (this.menu.cover_image) {
        this.url = URL.createObjectURL(this.menu.cover_image)
      }
    },
    getMainCategories() {
      CategoryAPI.getCategoriesTree(this.brand_id)
        .then((result) => {
          var parsedobj = JSON.parse(JSON.stringify(result.data.data))

          this.options = parsedobj
        })
        .catch((err) => {
          console.log(err)
        })
    },
    checkCategory(menu, index) {
      this.categories.length = index + 1
      if (!menu.id) {
        this.menu.category_id = null
        return
      }
      this.menu.category_id = menu.id
      CategoryAPI.getChildren(menu.id)
        .then((result) => {
          let resultArray = []
          result.data.data.data.map(function (item, key) {
            if (item.name != '') {
              resultArray.push({
                id: item.id,
                name: item.name_jp,
                parent_id: item.parent_id,
              })
            }
          })
          if (resultArray.length > 0) {
            this.categories.push(resultArray)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    resetForm() {
      this.errorMessages = []
      Object.keys(this.form).forEach((f) => {
        this.$refs[f].reset()
      })
    },
    savePostToDraft() {
      this.menu_status = 'draft'
      this.submit()
    },
    savePostAndPreview() {
      this.menu_status = 'inactive'
      this.submit()
    },
    submit() {
      this.formHasErrors = false
      Object.keys(this.rules).forEach((f) => {
        if (this.menu.has_submenu && f == 'price') {
          return
        }
        let success = this.$refs[f].validate(true)
        if (!success) {
          this.formHasErrors = true
        }
      })
      if (!this.formHasErrors) {
        this.createMenu()
      }
    },
    add() {
      this.menu.submenu.push({ description: '', price: '' })
    },

    remove(index) {
      this.menu.submenu.splice(index, 1)
    },

    // image upload
    uploadImageSuccess(formData, index, fileList) {
      this.fileList = fileList
    },
    beforeRemove(index, done, fileList) {},
    editImage(formData, index, fileList) {},

    createMenu() {
      this.loading = true
      MenuAPI.createMenu(this.formData, this.brand_id)
        .then((res) => {
          this.status = res.data.status
          this.statusMessage = res.data.message
          this.loading = false
          let payload = {
            status: res.data.status,
            message: res.data.message,
          }
          this.$store.commit('setAlert', payload)
          this.$router.push({
            name: 'Menu',
            params: { brand: this.$route.params.brand },
          })
        })
        .catch((e) => {
          this.loading = false
          this.errorMessages = e.data.errors
        })
    },
  },

  computed: {
    form() {
      let form = JSON.parse(JSON.stringify(this.menu))

      if (this.menu_status) form.status = this.menu_status
      else if (form.status) form.status = 'active'
      else form.status = 'inactive'

      if (!form.has_detail_page) {
        form.number_of_dishes = null
        form.description = null
        form.comment = null
        form.booking_info = null
        form.meta_description = null
        if (!form.show_homepage) {
          form.cover_image = null
        }
      }
      return form
    },
    formData() {
      var formData = new FormData()
      Object.keys(this.form).forEach((key) => {
        if (!(this.form[key] == null || this.form[key] === '')) {
          if (key == 'submenu') {
            formData.append(key, JSON.stringify(this.form[key]))
          } else {
            formData.append(key, this.form[key])
          }
        }
      })
      
      if (this.brand_id == 1) {
        formData.append(`photo_id[0]`, 0)
        formData.append(`photo_description[0]`, '')
        formData.append(`photo_image_0`, this.main_photo.icon)
      }

      for (let i = 0; i < this.photosarr.length; i++) {
        let key = this.brand_id == 1 ? i+1 : i

        // this.formData.append(`photo_url[]`, this.photosarr[i].icon)
        if (this.photosarr[i].id) {
          formData.append(`photo_id[${key}]`, this.photosarr[i].id)
        } else {
          formData.append(`photo_id[${key}]`, 0)
        }
        formData.append(`photo_description[${key}]`, this.photosarr[i].description)
        // formData.append(`photo_main[]`, this.photosarr[i].is_main)
        if (this.photosarr[i].icon) {
          formData.append(`photo_image_${key}`, this.photosarr[i].icon)
        } else {
          formData.append(`photo_image_${key}`, '')
        }
      }
      formData.append('shop_id', this.brand_id)
      formData.append('cover_image', this.menu.cover_image)

      formData.append('fileList', JSON.stringify(this.fileList))
      return formData
    },
  },

  beforeMount() {
    this.brand_id = this.$store.getters.brands.find(
      (x) => x.name === this.$route.params.brand
    ).id
    this.getMainCategories()
  },
  mounted() {
    if (parseInt(this.brand_id) == 1) {
      this.addphotos()
    }
  },
}
</script>

<style scoped>
.theme--light.v-icon:focus::after {
  opacity: 0;
}

#my-strictly-unique-vue-upload-multiple-image {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

h1,
h2 {
  font-weight: normal;
}

/* ul {
  list-style-type: none;
  padding: 0;
} */

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
.image-section >>> .image-container,
.image-section >>> .image-list-container {
  margin-left: auto;
  margin-right: auto;
  width: 300px;
  max-width: 300px;
}
.recommended-image {
  text-align: center;
}
</style>
