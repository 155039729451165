<template>
  <v-app>
    <main>
      <v-container fill-height>
        <v-row justify="center" align="center">
          <v-col cols="12" md="8" lg="6" xl="4">
            <v-card elevation="12" class="py-8">
              <v-card-text class="text-center">
                <div>
                  <v-img class="ml-auto mr-auto" src="/logo.png" width="150px" height="auto"></v-img>
                </div>
                <p class="text-center red--text" v-if="errors">
                  {{ errors }}
                </p>
                <v-form v-model="isValid">
                  <div class="mt-6">
                    <v-row justify="center">
                      <v-col cols="12" md="10">
                        <v-alert
                          v-if="alert"
                          border="left"
                          outlined
                          :type="alert.status"
                          dismissible
                        >
                          {{ alert.message }}
                        </v-alert>
                        <v-text-field
                          v-model="email"
                          :rules="rules.email"
                          label="メールアドレス"
                          name="email"
                          outlined
                          prepend-inner-icon="mdi-account-outline"
                          hide-details="auto"
                        ></v-text-field>

                        <v-text-field
                          v-model="password"
                          :rules="rules.password"
                          label="パスワード"
                          name="password"
                          outlined
                          prepend-inner-icon="mdi-lock-open"
                          :type="showPassword ? 'text' : 'password'"
                          :append-icon="
                            showPassword ? 'mdi-eye' : 'mdi-eye-off'
                          "
                          @click:append="showPassword = !showPassword"
                          hide-details="auto"
                          class="mt-6"
                        ></v-text-field>

                        <div class="d-flex align-center justify-end mt-4">
                          <!-- <v-checkbox label="Remember me"></v-checkbox> -->
                          <router-link :to="{ name: 'ForgotPassword' }"
                            >パスワードをお忘れですか？</router-link
                          >
                        </div>

                        <div class="mt-6">
                          <v-btn
                            block
                            class="text-capitalize"
                            large
                            color="primary"
                            :disabled="!isValid"
                            @click="login"
                            >ログイン
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </main>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Login',
  data() {
    return {
      showPassword: false,
      email: null,
      password: null,
      isValid: true,
      errors: null,
      rules: {
        email: [
          v => !!v || 'メールアドレスを入力しくてください。',
          v =>
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
            '正しいメールアドレスの形式で入力してください。'
        ],
        password: [
          v => !!v || 'パスワードを入力してください。',
          v => (v && v.length >= 6) || 'パスワードの長さは6文字以上必要です。'
          // v => /(?=.*[A-Z])/.test(v) || 'Must have one uppercase character',
          // v => /(?=.*\d)/.test(v) || 'Must have one number',
          // v => /([!@$%])/.test(v) || 'Must have one special character [!@#$%]'
        ]
      }
    }
  },

  methods: {
    login: function() {
      let email = this.email
      let password = this.password
      this.$store
        .dispatch('AUTH_REQUEST', { email, password })
        .then(
          response => {
            if (response.data.errors)
              this.errors = 'ログイン情報が間違っています。'
            else if (response.data.status == 'success'){
              if (response.data.data.user.user_type == 'admin' || response.data.data.user.shop_id == '2') {
                this.$router.push('/大連餃子基地DALIAN/menus')
              } else {
                this.$router.push('/プレミアム肉まん/menus')
              }
            }
          },
          error => {
            console.error(error)
          }
        )
        .catch(err => console.log(err))
    }
  },
  mounted() {
    if (this.alert != null) {
      setTimeout(() => {
        this.$store.commit('resetAlert')
      }, 5000)
    }
  },
  computed: mapState([
    // map this.count to store.state.count
    'alert'
  ])
}
</script>
<style scoped>
main {
  background-color: rgba(244, 245, 247, 1);
  height: 100vh;
}
</style>
