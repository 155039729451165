import ja from 'vuetify/src/locale/ja.ts'

export default {
  ...ja,

  dataTable: {
    ...ja.dataTable,
    itemsPerPageText: '表示中'
  },
  dataFooter: {
    ...ja.dataFooter,
    pageText: '{2}件中{0}-{1}表示中'
  }
}
