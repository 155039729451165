<template>
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>

    <v-alert v-if="alert" :type="alert.status" dismissible text>
      {{ alert.message }}
    </v-alert>

    <v-card elevation="2" class="pa-5 mt-5">
      <v-card-title>
        カテゴリ設定
        <v-col class="text-right">
          <v-btn
            small
            color="secondary white--text"
            :to="{ name: 'CreateCategory' }"
          >
            新規作成
          </v-btn>
        </v-col>
      </v-card-title>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="categories"
          :options.sync="options"
          :server-items-length="totalCategories"
          :loading="loading"
        >
          <template v-slot:item="{ item, index }">
            <tr>
              <td>
                {{ options.itemsPerPage * (options.page - 1) + (index + 1) }}
              </td>
              <td>{{ item.name_jp }}</td>
              <td>{{ item.status }}</td>
              <td>
                {{ moment(item.created_at).format('DD/MM/YYYY hh:mm a') }}
              </td>

              <td>
                <v-btn
                  x-small
                  icon
                  class="mr-2"
                  :to="{
                    name: 'EditCategory',
                    params: { id: item.id, brand: brand_name }
                  }"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-icon small @click="deleteItem(item)">
                  mdi-delete
                </v-icon>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
var moment = require('moment')
import CategoryAPI from '@/services/category'

export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: 'ホーム',
          disabled: false,
          to: { name: 'Dashboard' }
          // exact: true
        },
        {
          text: 'カテゴリ',
          disabled: false,
          to: { name: 'Category' },
          exact: true
        }
      ],
      totalCategories: 0,
      categories: [],
      loading: true,
      options: {},
      headers: [
        {
          text: 'ID',
          align: 'start',
          // sortable: false,
          value: 'id'
        },

        { text: 'カテゴリ名', value: 'name_jp' },
        { text: 'ステータス', value: 'status' },
        { text: '更新日', value: 'created_at' },
        { text: 'アクション', value: 'actions', sortable: false }
      ],
      brand_id: null,
      brand_name: this.$route.params.brand
    }
  },

  watch: {
    options: function(newVal, oldVal) {
      this.getCategoryFromApi()
    },
    '$route.params': function(newVal, oldVal) {
      this.getCategoryFromApi()
    }

    // options: {
    //   handler() {
    //     this.getCategoryFromApi()
    //   },
    //   deep: true
    // },
    // '$route.params': {
    //   handler() {
    //     this.getCategoryFromApi()
    //   },
    //   immediate: true
    // }
  },

  computed: {
    ...mapState(['alert']),
    reqParams() {
      return {
        shop_id: this.brand_id,
        itemsPerPage: this.options.itemsPerPage,
        page: this.options.page,
        sortBy: this.options.sortBy[0] || null,
        sortDesc: this.options.sortDesc[0]
      }
    }
  },

  mounted() {
    this.brand_id = this.$store.getters.brands.find(
      x => x.name === this.$route.params.brand
    ).id

    if (this.alert != null) {
      setTimeout(() => {
        this.$store.commit('resetAlert')
      }, 5000)
    }
  },

  methods: {
    moment: function() {
      return moment()
    },
    getCategoryFromApi() {
      this.loading = true
      CategoryAPI.getCategories(this.reqParams).then(res => {
        this.categories = res.data.data.data ? res.data.data.data : res.data.data
        this.totalCategories = res.data.data.total
        this.loading = false
      })
    },
    deleteItem(item) {
      this.$swal
        .fire({
          title: '本当に削除してもよろしいですか？',
          text: '一度削除すると元に戻せません。',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '削除',
          cancelButtonText: '戻る'
        })
        .then(result => {
          if (result.isConfirmed) {
            CategoryAPI.deleteCategory(item.id)
              .then(response => {
                if (response.data.status == 'success') {
                  this.$swal.fire(
                    'Deleted!',
                    'Category has been deleted.',
                    'success'
                  )
                  this.categories.splice(
                    this.categories.findIndex(e => e.id == item.id),
                    1
                  )
                }
              })
              .catch(e => {
                this.$swal.fire(
                  'Server error',
                  'Oops! something wrong, please try again later',
                  'error'
                )
              })
          }
        })
    }
  }
}
</script>
