<template>
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>

    <v-card elevation="2" class="pa-5 mt-5">
      <v-card-title>
        消費税設定
      </v-card-title>

      <div v-if="shop">
        <v-card-text>
          <v-alert
            v-if="alert"
            :type="alertStatus"
            :value="alert"
            dismissible
            text
          >
            {{ statusMessage }}
          </v-alert>
          <span>メニューの価格に対して税込/税別の表記設定ができます</span>
          <v-switch v-model="status" ref="status" label="税込表示"></v-switch>

          <v-divider class="mt-12"></v-divider>

          <v-card-actions>
            <v-btn text :to="{ name: 'Dashboard' }">
              戻る
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="submit"
              :loading="loading"
              :disabled="loading"
            >
              更新
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </div>
    </v-card>
  </div>
</template>

<script>
import TaxAPI from '@/services/tax'
export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: 'ホーム',
          disabled: false,
          to: { name: 'Dashboard' }
        },
        {
          text: '消費税',
          disabled: false,
          to: { name: 'Tax' },
          exact: true
        }
      ],
      loading: false,
      errorMessages: '',
      shop: null,
      status: true,
      alert: false,
      alertStatus: null,
      brand_id: null
    }
  },

  mounted() {
    this.brand_id = this.$store.getters.brands.find(
      x => x.name === this.$route.params.brand
    ).id
    this.getTax(this.brand_id)
  },

  methods: {
    getTax(id) {
      this.loading = true
      TaxAPI.getTax(id)
        .then(response => {
          this.loading = false
          this.shop = response.data.data
          this.status = this.shop.tax_inclusive == 1 ? true : false
        })
        .catch(e => {
          this.loading = false
          console.log(e)
        })
    },
    submit() {
      this.formHasErrors = false

      if (!this.formHasErrors) {
        this.updateTax()
      }
    },

    updateTax() {
      this.loading = true
      TaxAPI.updateTax(this.formData)
        .then(response => {
          this.loading = false
          let payload = {
            status: response.data.status,
            message: response.data.message
          }
          this.$store.commit('setAlert', payload)

          this.alertStatus = response.data.status
          this.statusMessage = '更新が完了しました'
          this.alert = true
          setTimeout(() => {
            this.alert = false
          }, 5000)
        })
        .catch(e => {
          this.loading = false
          console.log(e)
        })
    }
  },

  computed: {
    formData() {
      var formData = new FormData()
      formData.append('shop_id', this.brand_id)
      formData.append('status', this.status)
      return formData
    }
  }
}
</script>
